import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../../helpers/Translation'
import {
  DEFAULT_THUMBNAIL_TYPE,
  EMPTY_IMG,
  IMAGE_ERROR_CLASS,
  HIDE_DROPDOWN_TIMEOUT,
  IMAGE_ERROR_CODES,
  IMAGE_PREVIEWS_WIDTHS,
  IMAGE_SIZES,
  IMAGE_STATUSES,
  IMAGE_THUMB_PADDING,
  SKELETON_CLASS,
  SELECTED_IMAGES_CLASS,
  SELECTABLE_CLASS,
  TEST_IMAGE_TYPE,
  SYSTEM_USER_ID,
  ALL_LAYERS_KEY,
  PATH_COLORS,
  SHOW_PATH_PREVIEW_CLASS,
  PREVIEW_GRID_CLASS,
  NOSELECT_CLASS,
  PX_TO_REM,
  IMAGE_PREVIEW_MIN_HEIGHT,
  IMAGE_TYPES,
  PREVIEW_NOT_FOUND_BG_COLOR,
  TRANSPARENT_BG,
  ACTIVE_CLASS,
} from '../../../../helpers/Constants'
import {
  brokenFilePreview,
  notFoundPreview,
  isBrokenImage,
  removeClass,
  dragElement,
  useEventListener,
  getEndpointUrl,
  removeAllChildren,
  isAltKey,
  isAdminRole,
  getPathColor,
  buildGrids,
  buildPath,
} from '../../../../helpers/Utils'
import { API_ENDPOINTS_GALLERY } from '../../../../helpers/Urls'

import Tooltip from '../../../../components/Tooltip'
import Checkbox from '../../../../components/Checkbox'
import Typography from '../../../../components/Typography'
import RadioGroup from '../../../../components/RadioGroup'

import Comment from '../../Comment'

import { ReactComponent as PreviewCheckSvg } from '../../../../svg/preview_check.svg'
import { ReactComponent as PreviewUndoSvg } from '../../../../svg/preview_undo.svg'
import { ReactComponent as PreviewImageSvg } from '../../../../svg/preview_image.svg'
import { ReactComponent as PreviewDownloadSvg } from '../../../../svg/preview_download.svg'
import { ReactComponent as PreviewDuplicateSvg } from '../../../../svg/preview_duplicate.svg'
import { ReactComponent as PreviewMoreSvg } from '../../../../svg/preview_more.svg'
import { ReactComponent as PreviewSendSvg } from '../../../../svg/preview_send.svg'
import { ReactComponent as PreviewDeleteSvg } from '../../../../svg/preview_trash.svg'
import { ReactComponent as PreviewEditedBySvg } from '../../../../svg/preview_edited_by.svg'
import { ReactComponent as OverlayCloseIconSvg } from '../../../../svg/overlay_close_icon.svg'
import { ReactComponent as EyeSvg } from '../../../../svg/eye.svg'
import { ReactComponent as EyeDropperSvg } from '../../../../svg/action_eye_dropper.svg'
import { ReactComponent as ActionLikeSvg } from '../../../../svg/action_like.svg'
import { ReactComponent as ActionDislikeSvg } from '../../../../svg/action_dislike.svg'
import { ReactComponent as PathOutsideImageIconSvg } from '../../../../svg/path_outside_image.svg'
import { ReactComponent as LayerMaskIconSvg } from '../../../../svg/layer_mask.svg'
import { ReactComponent as LayerVisibleIconSvg } from '../../../../svg/layer_visible.svg'
import { ReactComponent as LayerInvisibleIconSvg } from '../../../../svg/layer_invisible.svg'

import './index.scss'

const getPreviewStyles = (width, height, imageTagColor) => {
  const previewWidth = parseFloat(width)
  const previewHeight = parseFloat(height)

  const previewWrapWidth = previewWidth + (2 * IMAGE_THUMB_PADDING)
  const previewWrapHeight = previewHeight + (2 * IMAGE_THUMB_PADDING)

  const imageWrapStyle = {
    width: `${previewWrapWidth}px`,
    backgroundColor: imageTagColor,
  }

  const previewStyle = {
    width: `${previewWidth}px`,
    height: `${previewHeight}px`,
  }

  const previewWrapMarginTop = IMAGE_PREVIEW_MIN_HEIGHT > previewHeight
    ? `${(IMAGE_PREVIEW_MIN_HEIGHT - previewHeight) / 2}` : 0

  const previewBgStyle = {
    width: `${previewWrapWidth}px`,
    height: `${previewWrapHeight}px`,
    marginTop: `${previewWrapMarginTop}px`,
    marginBottom: `${previewWrapMarginTop}px`,
    backgroundImage: `url(${TRANSPARENT_BG})`,
  }

  return [previewStyle, imageWrapStyle, previewBgStyle]
}

const getPreviewClass = (id, isUrlShown, st, commentsLength, selectedImages, isAdmin) => {
  const status = parseInt(st, 10)

  let previewClass = 'image-preview'
  if (!isUrlShown) previewClass += ` ${SKELETON_CLASS}`

  let previewWrapClass = 'gallery-image--wrap'
  if (commentsLength) previewWrapClass += ' gallery-image--wrap__error'

  if (status === IMAGE_STATUSES.internalReference
    || status === IMAGE_STATUSES.backgroundCode
    || status === IMAGE_STATUSES.colorationReference
    || status === IMAGE_STATUSES.colorReference
    || status === IMAGE_STATUSES.inputSpReference
  ) previewWrapClass += ' gallery-image--wrap__reference'
  else if (status === IMAGE_STATUSES.confirmed && isAdmin) previewWrapClass += ' gallery-image--wrap__confirmed'
  else if (status === IMAGE_STATUSES.broken) previewWrapClass += ' gallery-image--wrap__broken'

  if (selectedImages.includes(id)) previewWrapClass += ` ${SELECTED_IMAGES_CLASS}`

  return [previewClass, previewWrapClass]
}

const getPreviewSrc = (image, links, isOriginalImage, imageSize, selectedLayer, layerUrls) => {
  let previewSrc = EMPTY_IMG

  if (isBrokenImage(image?.status)) previewSrc = brokenFilePreview
  else if (
    selectedLayer !== `${ALL_LAYERS_KEY}-${image?.id}`
    && image?.layer.some((l) => l.id === selectedLayer)
  ) {
    const layerId = image?.layer.find((l) => l.id === selectedLayer).id
    previewSrc = layerUrls?.[image?.id]?.[layerId]
  } else if (image?.is_url_shown) previewSrc = image?.url
  else if (isOriginalImage) previewSrc = links[image?.original_image_id]?.[imageSize]
  else if (links[image?.id]) previewSrc = links[image?.id]?.[imageSize]

  return previewSrc
}

const handlePreviewImgError = (e) => {
  const previewImg = e.currentTarget
  previewImg.onerror = null // prevents looping
  previewImg.src = notFoundPreview
}

const handlePreviewImgLoad = (e) => {
  const previewImg = e.currentTarget

  if (previewImg.src !== `${window.location.origin}${EMPTY_IMG}`) {
    removeClass(previewImg, SKELETON_CLASS)
    previewImg.style.backgroundColor = 'transparent'
  }

  if (previewImg.src === `${window.location.origin}${notFoundPreview}`
    || previewImg.src === `${window.location.origin}${brokenFilePreview}`) {
    previewImg.style.backgroundColor = PREVIEW_NOT_FOUND_BG_COLOR
  }
}

const Preview = ({
  image, index, refreshGallery, resetInterval,
}) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isDraggableOpened: state.layout.isDraggableOpened,
    draggableIndex: state.layout.draggableIndex,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateOverlayOpened: actions.layout.updateOverlayOpened,
    updateDraggableOpened: actions.layout.updateDraggableOpened,
    updateDraggableIndex: actions.layout.updateDraggableIndex,
    updateDialogModalOpened: actions.layout.updateDialogModalOpened,
    updateDialogModalState: actions.layout.updateDialogModalState,
  }))

  const orderState = useStoreState((state) => ({
    images: state.order.images,
    links: state.order.links,
    imageType: state.order.imageType,
    imageSize: state.order.imageSize,
    selectedImages: state.order.selectedImages,
    gallery: state.order.gallery,
    selectedImagePaths: state.order.selectedImagePaths,
    paths: state.order.paths,
    selectedLayer: state.order.selectedLayer,
    layerUrls: state.order.layerUrls,
    pathRange: state.order.pathRange,
    selectedGrids: state.order.selectedGrids,
    selectedLayers: state.order.selectedLayers,
  }))

  const orderActions = useStoreActions((actions) => ({
    setImagesToBeDeleted: actions.order.setImagesToBeDeleted,
    setOverlayImgIndex: actions.order.setOverlayImgIndex,
    getLinks: actions.order.getLinks,
    imageAction: actions.order.imageAction,
    setSelectedImages: actions.order.setSelectedImages,
    setDownloadUrls: actions.order.setDownloadUrls,
    removeError: actions.order.removeError,
    setImageToBeAllowedOverwrite: actions.order.setImageToBeAllowedOverwrite,
    setSelectedImagePaths: actions.order.setSelectedImagePaths,
    getPath: actions.order.getPath,
    setSelectedLayers: actions.order.setSelectedLayers,
    getLayer: actions.order.getLayer,
  }))

  const isOutputImage = !!image.original_image_id
  const commentsLength = image.image_comments.length

  const [previewStyle, imageWrapStyle, previewBgStyle] = getPreviewStyles(
    image.width_preview,
    image.height_preview,
    image.image_tag_color,
  )

  if (orderState.imageSize === IMAGE_SIZES.medium) imageWrapStyle.minWidth = `${IMAGE_PREVIEWS_WIDTHS.medium}px`
  else if (orderState.imageSize === IMAGE_SIZES.large) imageWrapStyle.minWidth = `${IMAGE_PREVIEWS_WIDTHS.large}px`

  const [previewClass, previewWrapClass] = getPreviewClass(
    image.id,
    image.is_url_shown,
    image.status,
    commentsLength,
    orderState.selectedImages,
    userState.user.is_admin,
  )

  const draggableImageRef = useRef(null)
  const isMouseOverRef = useRef(false)
  const isOpenedRef = useRef(false)
  const filenameRef = useRef(null)
  const folderRef = useRef(null)

  const getAvailableWidth = (isFolder = false) => {
    let availableWidth = -10 // extract padding
    if (!isFolder) {
      availableWidth = -30 // extract padding more
      availableWidth -= (image.id.toString().length * 5)
    }

    if (orderState.imageSize === IMAGE_SIZES.medium) availableWidth += IMAGE_PREVIEWS_WIDTHS.medium
    else if (orderState.imageSize === IMAGE_SIZES.large) availableWidth += IMAGE_PREVIEWS_WIDTHS.large
    else availableWidth += IMAGE_PREVIEWS_WIDTHS.small

    return availableWidth
  }

  const [layerValues, setLayerValues] = useState({})
  const [filenameAvailableWidth, setFilenameAvailableWidth] = useState(getAvailableWidth())
  const [folderAvailableWidth, setFolderAvailableWidth] = useState(getAvailableWidth(true))
  const [isOriginalImage, setIsOriginalImage] = useState(false)
  const [isBottomMenuOpened, setIsBottomMenuOpened] = useState(false)
  const [isMouseOverDraggable, setIsMouseOverDraggable] = useState(false)
  const [previewSrc, setPreviewSrc] = useState(getPreviewSrc(
    image,
    orderState.links,
    isOriginalImage,
    orderState.imageSize,
    orderState.selectedLayers[image.id],
    orderState.layerUrls,
  ))

  const dotsText = '...'
  const truncateIterationLimit = 1000 // iterations to prevent infinite loop

  useEffect(() => {
    if (!image.name_without_format || !image.format) return

    const row = filenameRef.current
    if (row.nodeName === 'SPAN') {
      // remove all children from row
      removeAllChildren(row)

      // reset to initial value
      const nameWithoutFormat = document.createTextNode(row.dataset.nameWithoutFormat)
      if (row.dataset.wrongFileName === 'true') {
        const spanNameWithoutFormat = document.createElement('span')
        spanNameWithoutFormat.setAttribute('class', IMAGE_ERROR_CLASS)
        spanNameWithoutFormat.dataset.filename = 'true'
        spanNameWithoutFormat.appendChild(nameWithoutFormat)
        row.appendChild(spanNameWithoutFormat)
      } else {
        row.appendChild(nameWithoutFormat)
      }

      row.appendChild(document.createTextNode('.'))

      const format = document.createTextNode(row.dataset.format)
      if (row.dataset.wrongFormat === 'true') {
        const spanFormat = document.createElement('span')
        spanFormat.setAttribute('class', IMAGE_ERROR_CLASS)
        spanFormat.dataset.format = 'true'
        spanFormat.appendChild(format)
        row.appendChild(spanFormat)
      } else {
        row.appendChild(format)
      }

      if (row.offsetWidth > filenameAvailableWidth && 1) {
        let textNode = row.firstChild

        if (textNode.nodeName !== '#text') {
          textNode = textNode.firstChild
        }
        let value = dotsText + textNode.nodeValue

        let charLimit = 0
        do {
          value = dotsText + value.substring(dotsText.length + 1)
          textNode.nodeValue = value
          charLimit += 1
        } while (charLimit < truncateIterationLimit && row.offsetWidth > filenameAvailableWidth)
      }
    }
  }, [
    filenameAvailableWidth,
    image.name_without_format,
    image.format,
    image.wrong_file_name,
    image.wrong_format,
  ])

  useEffect(() => {
    if (!image.folder) return

    const row = folderRef.current
    if (row.nodeName === 'SPAN') {
      row.innerText = row.dataset.folder // reset to initial value

      if (row.offsetWidth > folderAvailableWidth) {
        let value = dotsText + row.dataset.folder

        let charLimit = 0
        do {
          value = dotsText + value.substring(dotsText.length + 1)
          row.innerText = value
          charLimit += 1
        } while (charLimit < truncateIterationLimit && row.offsetWidth > folderAvailableWidth)
      }
    }
  }, [folderAvailableWidth, image.folder])

  useEffect(() => {
    if (layoutState.isDraggableOpened && index === layoutState.draggableIndex) {
      const dragEvent = dragElement(draggableImageRef.current)
      draggableImageRef.current.addEventListener('mousedown', dragEvent)
    }
  }, [layoutState.isDraggableOpened, index, layoutState.draggableIndex, image.id])

  useEffect(() => {
    setIsOriginalImage(false)
    setFilenameAvailableWidth(getAvailableWidth())
    setFolderAvailableWidth(getAvailableWidth(true))
  }, [orderState.imageSize])

  useEffect(() => {
    setPreviewSrc(getPreviewSrc(
      image,
      orderState.links,
      isOriginalImage,
      orderState.imageSize,
      orderState.selectedLayers[image.id],
      orderState.layerUrls,
    ))
  }, [
    image,
    isOriginalImage,
    orderState.imageSize,
    orderState.selectedLayers[image.id],
    orderState.links,
    orderState.layerUrls,
  ])

  useEffect(() => {
    const layers = {}
    image?.layer?.forEach((layer) => {
      layers[layer.id] = (
        <div className="layer-item">
          {parseInt(layer.is_visible, 2) ? <LayerVisibleIconSvg /> : <LayerInvisibleIconSvg />}
          {parseInt(layer.has_layer_mask, 2) ? <LayerMaskIconSvg /> : null}
          <Typography label={layer.name.replace(/&nbsp;/g, ' ')} />
        </div>
      )
    })
    setLayerValues({
      [`${ALL_LAYERS_KEY}-${image.id}`]: (
        <div className="layer-item">
          <Typography label={Translation.all_layers} />
        </div>
      ),
      ...layers,
    })
  }, [image])

  const isAllImagesLoaded = () => orderState.images.length === (
    // eslint-disable-next-line no-nested-ternary
    orderState.imageType === IMAGE_TYPES.input
      ? orderState.gallery?.input_count
      // eslint-disable-next-line no-nested-ternary
      : orderState.imageType === IMAGE_TYPES.output
        ? orderState.gallery?.output_count
        : orderState.imageType === IMAGE_TYPES.compare
          ? orderState.gallery?.combined_count : 0
  )

  const onPreviewClick = (e) => {
    e.stopPropagation()

    if (e.shiftKey) return

    orderActions.setOverlayImgIndex(index)
    layoutActions.updateOverlayOpened(true)
  }

  const toggleOriginalImage = (e) => {
    e.stopPropagation()
    if (!isAllImagesLoaded()) return
    setIsOriginalImage(!isOriginalImage)

    if (orderState.links[image.original_image_id] && orderState.links[image.original_image_id][IMAGE_SIZES.small]) return

    orderActions.getLinks({
      image_ids: [image.original_image_id],
      s3_paths: [image.image_resource[image.original_image_id].s3_path],
      original_s3_paths: [image.image_resource[image.original_image_id].original_s3_path],
      formats: [image.image_resource[image.original_image_id].format],
      image_sizes: [orderState.imageSize || IMAGE_SIZES.small],
      thumbnail_types: [DEFAULT_THUMBNAIL_TYPE],
    })
  }

  const openDraggable = () => {
    layoutActions.updateDraggableOpened(true)
    layoutActions.updateDraggableIndex(index)

    // TODO - this url should be shown in draggable div
    orderActions.getLinks({
      image_ids: [image.id],
      s3_paths: [image.image_resource[image.id].s3_path],
      original_s3_paths: [image.image_resource[image.id].original_s3_path],
      formats: [image.image_resource[image.id].format],
      image_sizes: [IMAGE_SIZES.medium],
      thumbnail_types: [DEFAULT_THUMBNAIL_TYPE],
    })
  }

  const closeDraggable = () => {
    layoutActions.updateDraggableOpened(false)
    layoutActions.updateDraggableIndex(null)
  }

  const toggleDraggable = (e) => {
    e.stopPropagation()

    if (!isAllImagesLoaded()) return

    if (layoutState.isDraggableOpened && index === layoutState.draggableIndex) {
      closeDraggable()
    } else {
      openDraggable()
    }
  }

  useEventListener('keyup', (e) => {
    if (!layoutState.isDraggableOpened) return

    if (e.key === 'Escape') closeDraggable()
  })

  const openBottomMenu = (e) => {
    e.stopPropagation()
    if (!isAllImagesLoaded()) return
    setIsBottomMenuOpened(!isBottomMenuOpened)
    isOpenedRef.current = !isBottomMenuOpened
  }

  const onHandleMouseLeave = () => {
    isMouseOverRef.current = false

    setTimeout(() => {
      if (isOpenedRef.current && !isMouseOverRef.current) {
        setIsBottomMenuOpened(false)
      }
    }, HIDE_DROPDOWN_TIMEOUT)
  }

  const onHandleMouseEnter = () => {
    isMouseOverRef.current = true
  }

  const isConfirmedApproved = (status, imageTagColor) => !(
    (userState.user.is_admin && parseInt(status, 10) === IMAGE_STATUSES.confirmed)
    || (!userState.user.is_admin && imageTagColor))

  const onHandleImageAction = async (e, action) => {
    e.stopPropagation()
    if (!isAllImagesLoaded()) return
    const res = await orderActions.imageAction({
      url: action,
      body: {
        image_ids: [image.id],
        ...(action === getEndpointUrl(userState.user.role_after_login, API_ENDPOINTS_GALLERY.confirm)
          || action === getEndpointUrl(userState.user.role_after_login, API_ENDPOINTS_GALLERY.approve)
          ? { status: isConfirmedApproved(image.status, image.image_tag_color) }
          : {}),
      },
    })

    if (res.success) {
      refreshGallery()
      resetInterval()
    }
  }

  const referenceImageIcon = () => {
    const status = parseInt(image.status.toString(), 10)
    if (status === IMAGE_STATUSES.internalReference
      || status === IMAGE_STATUSES.backgroundCode
      || status === IMAGE_STATUSES.inputSpReference
    ) {
      return <EyeSvg style={{ marginBottom: PX_TO_REM['1'] }} />
    }
    if (status === IMAGE_STATUSES.colorationReference || status === IMAGE_STATUSES.colorReference) {
      return <EyeDropperSvg className="width-8 height-8" />
    }
    return null
  }

  const toggleSelect = (e) => {
    e.stopPropagation()
    if (isAllImagesLoaded()) {
      const imageId = parseInt(e.currentTarget.dataset.imageId, 10)
      const selectedImagesCopy = [...orderState.selectedImages]
      const imageIndex = selectedImagesCopy.indexOf(imageId)
      if (imageIndex === -1) {
        selectedImagesCopy.push(imageId)
      } else {
        selectedImagesCopy.splice(imageIndex, 1)
      }
      orderActions.setSelectedImages(selectedImagesCopy)
    }
  }

  const onHandleDelete = (e) => {
    e.stopPropagation()
    setIsBottomMenuOpened(false)
    isOpenedRef.current = false
    orderActions.setImagesToBeDeleted([image.id])
    layoutActions.updateDialogModalState(0)
    layoutActions.updateDialogModalOpened(true)
  }

  const onHandleSend = (e) => {
    onHandleImageAction(e, getEndpointUrl(userState.user.role_after_login, API_ENDPOINTS_GALLERY.send)).then(() => { })
    setIsBottomMenuOpened(false)
    isOpenedRef.current = false
  }

  const onDownload = async (e) => {
    e.stopPropagation()
    if (!isAllImagesLoaded()) return
    const res = await orderActions.imageAction({
      url: getEndpointUrl(userState.user.role_after_login, API_ENDPOINTS_GALLERY.download),
      body: {
        image_ids: [image.id],
        original_s3_paths: [image.image_resource[image.id].original_s3_path],
      },
    })
    if (res.success) {
      Object.keys(res.urls).forEach(() => {
        orderActions.setDownloadUrls(res.urls)
        setTimeout(() => {
          orderActions.setDownloadUrls({})
        }, 1000)
      })
    }
  }

  const removeErrorCode = async (e, errorCode) => {
    if (!isAdminRole(userState.user.role_after_login)) return

    if (isAltKey(e)) {
      e.stopPropagation()

      if (errorCode === IMAGE_ERROR_CODES.wrongFileName) {
        removeClass(e.currentTarget.firstChild, IMAGE_ERROR_CLASS)
      } else if (errorCode === IMAGE_ERROR_CODES.wrongFormat) {
        removeClass(e.currentTarget.lastChild, IMAGE_ERROR_CLASS)
      } else {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
      }

      const res = await orderActions.removeError({
        role_after_login: userState.user.role_after_login,
        body: {
          image_ids: [image.id],
          error_codes: [errorCode],
        },
      })
      if (res) {
        refreshGallery()
        resetInterval()
      }
    }
  }

  const handleFileNameClick = async (e) => {
    if (e.target.dataset.filename === 'true') {
      removeErrorCode(e, IMAGE_ERROR_CODES.wrongFileName).then(() => { })
    } else if (e.target.dataset.format === 'true') {
      removeErrorCode(e, IMAGE_ERROR_CODES.wrongFormat).then(() => { })
    }
  }

  const handleClearUpload = (e) => {
    e.stopPropagation()
    orderActions.setImageToBeAllowedOverwrite(image.id)
    layoutActions.updateDialogModalState(3)
    layoutActions.updateDialogModalOpened(true)
  }

  const onSelectPath = async (path) => {
    const selectedImagePathsCopy = { ...orderState.selectedImagePaths }
    if (!selectedImagePathsCopy[image.id]) selectedImagePathsCopy[image.id] = {}
    selectedImagePathsCopy[image.id][path] = !selectedImagePathsCopy[image.id][path]
    orderActions.setSelectedImagePaths(selectedImagePathsCopy)

    if (!selectedImagePathsCopy?.[image.id]?.[path]) {
      document.getElementById(`${image.id}-${path}-preview`)?.remove()
      return
    }

    if (orderState.paths[image.id]?.[image.path?.[path]?.line.s3_path]) {
      return
    }
    await orderActions.getPath({
      image_ids: [
        image.id,
      ],
      s3_paths: [
        image.path[path]?.line.s3_path,
      ],
    })
  }

  const addPath = (path) => {
    const elementString = orderState.paths?.[image.id]?.[image.path?.[path]?.line.s3_path]
    if (!elementString || document.getElementById(`${image.id}-${path}-preview`)) return
    const pathColorIndex = Object.keys(image.path).map((key) => key).findIndex((value) => value === path)

    const svgElement = buildPath(
      `${image.id}-${path}-preview`,
      SHOW_PATH_PREVIEW_CLASS,
      elementString,
      image.width_preview,
      image.height_preview,
      pathColorIndex,
      orderState.pathRange,
    )

    document.querySelector(`#image-${image.id}`).insertAdjacentElement('afterend', svgElement)

    svgElement?.addEventListener('click', (e) => {
      onPreviewClick(e)
    })
  }

  const onHandleLayerChange = (e) => {
    orderActions.setSelectedLayers({ imageId: image.id, layerId: e.target.value })
  }

  const onHandleMouseEnterDraggable = () => {
    setIsMouseOverDraggable(true)
  }

  const onHandleMouseLeaveDraggable = () => {
    setIsMouseOverDraggable(false)
  }

  useEffect(() => {
    Object.keys(orderState.selectedImagePaths[image.id] || {}).forEach((path) => {
      if (orderState.selectedImagePaths[image.id][path]) {
        addPath(path)
      }
    })
  }, [orderState.selectedImagePaths[image.id], orderState.paths[image.id]])

  useEffect(() => {
    orderState.selectedGrids.forEach((grid) => {
      if (
        document
          .querySelector(
            `.${PREVIEW_GRID_CLASS}.grid-${image.id}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`,
          )
      ) {
        return
      }
      const color = PATH_COLORS[orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)]

      const svgElement = buildGrids(
        grid,
        orderState.gallery?.grids,
        image.width,
        image.height,
        image.width_preview,
        image.height_preview,
        color,
        `${PREVIEW_GRID_CLASS} grid-${image.id}`,
      )

      document.querySelector(`#image-${image.id}`).insertAdjacentElement('afterend', svgElement)

      svgElement?.addEventListener('click', (e) => {
        onPreviewClick(e)
      })
    })
  }, [orderState.selectedGrids])

  useEffect(() => {
    const selectedLayerTemp = orderState.selectedLayers[image.id]
    if (
      selectedLayerTemp
      && selectedLayerTemp !== `${ALL_LAYERS_KEY}-${image.id}`
      && !orderState.layerUrls?.[image.id]?.[orderState.selectedLayers[image.id]]
    ) {
      const fn = async () => {
        orderActions.getLayer({
          role_after_login: userState.user.role_after_login,
          body: {
            ids: [parseInt(selectedLayerTemp, 10)],
            image_ids: [image.id],
            image_sizes: [IMAGE_SIZES.extraLarge],
          },
        })
      }

      fn().then(() => { })
    }
  }, [orderState.selectedLayers[image.id]])

  return (
    <div
      id={`image-${image.id}--wrap`}
      className={`${SELECTABLE_CLASS} ${previewWrapClass}`}
      style={imageWrapStyle}
      data-image-id={image.id}
      onClick={toggleSelect}
    >
      <div className="image-preview--bg" style={previewBgStyle}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          id={`image-${image.id}`}
          onClick={onPreviewClick}
          src={previewSrc}
          data-id={image.id}
          data-index={index}
          onLoad={handlePreviewImgLoad}
          onError={handlePreviewImgError}
          className={previewClass}
          style={previewStyle}
          alt=""
        />

        {(parseInt(image.status.toString(), 10) === IMAGE_STATUSES.internalReference
          || parseInt(image.status.toString(), 10) === IMAGE_STATUSES.backgroundCode
          || parseInt(image.status.toString(), 10) === IMAGE_STATUSES.inputSpReference
          || parseInt(image.status.toString(), 10) === IMAGE_STATUSES.colorationReference
          || parseInt(image.status.toString(), 10) === IMAGE_STATUSES.colorReference)
          && (
            <div className="reference-badge">
              {referenceImageIcon()}
              {Translation.reference}
            </div>
          )}

        {image.is_test_image === TEST_IMAGE_TYPE && (
          <div className="image-test-badge">
            {Translation.test_image}
          </div>
        )}
      </div>

      <div className="image-preview--details">
        <div className="image-preview--details__filename image-preview--details__border">
          <span className="image-preview--details__filename--id">
            <Tooltip
              dataToCopy={isOutputImage ? image.original_image_id.toString() : image.id.toString()}
              text={Translation.click_to_copy}
              copiedText={`${Translation.image_id} ${Translation.copied}`}
            >
              <span>
                {
                  (isOutputImage) ? image.original_image_id : image.id
                }
              </span>
            </Tooltip>
          </span>

          <span className="image-preview--details__filename--filename">
            <Tooltip
              dataToCopy={`${image.name_without_format}.${image.format}`}
              text={`${image.name_without_format}.${image.format}`}
              copiedText={`${Translation.filename} ${Translation.copied}`}
            >
              <span
                ref={filenameRef}
                data-wrong-file-name={image.wrong_file_name}
                data-wrong-format={image.wrong_format}
                data-name-without-format={image.name_without_format}
                data-format={image.format}
                onClick={handleFileNameClick}
              >
                {' '}
              </span>
            </Tooltip>
          </span>
        </div>

        {image.folder && (
          <div className="image-preview--details--folder image-preview--details__border">
            <Tooltip
              dataToCopy={`/ ${image.folder} / ${image.name_without_format}.${image.format}`}
              text={`/ ${image.folder} /`}
              copiedText={`${Translation.folder_with_filename} ${Translation.copied}`}
            >
              <span
                ref={folderRef}
                data-folder={`/ ${image.folder} /`}
              >
                {' '}
              </span>
            </Tooltip>
          </div>
        )}

        {isAdminRole(userState.user.role_after_login) && image.edited_by && (
          <div
            className="image-preview--details__edited-by image-preview--details__border"
            onClick={handleClearUpload}
          >
            <Tooltip
              text={`${Translation.uploaded_by} 
               ${parseInt(image?.edited_by_user_id, 10) === orderState?.gallery?.order?.user_id
                ? Translation.client_label : image.edited_by}.
               ${Translation.click_allow_sp_overwriting}.`}
            >
              <span className={
                parseInt(image?.edited_by_user_id, 10) === SYSTEM_USER_ID
                  ? 'edited-by--crossed-name' : ''
              }
              >
                {parseInt(image?.edited_by_user_id, 10) === orderState?.gallery?.order?.user_id
                  ? Translation.client_label : image.edited_by}
              </span>
              {' '}
              <PreviewEditedBySvg />
              {' '}
              <span className="data-timestamp" data-timestamp={image.timestamp_edited}>
                {image.timestamp_edited}
              </span>
            </Tooltip>
          </div>
        )}

        <div className="image-preview--details__sizes--wrap image-preview--details__border">
          <div className="image-preview--details__sizes">
            {image.width_error ? (
              <span className="image-error" onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.widthError)}>
                {image.width}
              </span>
            ) : image.width}
            {' '}
            {Translation.times_x_short}
            {' '}
            {image.height_error ? (
              <span className="image-error" onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.heightError)}>
                {image.height}
              </span>
            ) : image.height}
            {' '}
            {Translation.px}
            {' '}
            /
            {' '}
            {image.dpi_error ? (
              <span className="image-error" onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.dpiError)}>
                {image.dpi}
              </span>
            ) : image.dpi}
            {' '}
            {Translation.dpi}
            {' '}
            /
            {' '}
            {image.size_mb}
            {' '}
            {Translation.mb}
          </div>

          <div className="image-preview--details__colour-space">
            {/* eslint-disable-next-line no-nested-ternary */}
            {image.color_space_error ? (
              <span className="image-error" onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.colorSpaceError)}>
                {image.colour_space ? image.colour_space : Translation.no_colour_space}
              </span>
            ) : image.colour_space ? image.colour_space : Translation.no_colour_space}
          </div>

          <div className="image-preview--details__bg-colour">
            {/* eslint-disable-next-line no-nested-ternary */}
            {image.no_bg_color ? (
              <span className="image-error" onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.noBgColor)}>
                {image.background_color
                  ? `${Translation.detected_background}: ${image.background_color}`
                  : Translation.background_colour_not_detected}
              </span>
            ) : image.background_color
              ? `${Translation.detected_background}: ${image.background_color}` : Translation.background_colour_not_detected}
          </div>

          <div className="image-preview--details__colour-depth">
            {Translation.colour_depth}
            :
            {' '}
            {image.colour_depth}
            {' '}
            {Translation.bits}
          </div>
        </div>

        {image.layer.length > 0 && (
          <div className="image-preview--details__layers">
            <RadioGroup
              container="previewImageLayer"
              values={layerValues}
              onChange={onHandleLayerChange}
              value={orderState.selectedLayers[image.id]}
            />
          </div>
        )}

        {image.prices_extra && (
          <div className="image-preview--details__extra-price image-preview--details__border">
            {image.prices_extra.map((pricesExtra, formatIndex) => (
              <div key={pricesExtra.id}>
                {formatIndex + 1}
                .
                {' '}
                {Translation.format}
                :
                {' '}
                <span>{pricesExtra.price}</span>
                {' '}
                <span>{orderState?.gallery?.order.currency}</span>
              </div>
            ))}
          </div>
        )}

        {image.exif && (
          <div className="image-preview--details__exif image-preview--details__border
            scrollbar-overflow scrollbar-overflow__small"
          >
            {Object.keys(image.exif).map((key) => (
              <div key={key}>
                {`${key}: ${image.exif[key]}`}
              </div>
            ))}
          </div>
        )}

        {(image.photoshop_instructions || image.photoshop_description || image.photoshop_iptc) && (
          <div className="image-preview--details__photoshop image-preview--details__border
            scrollbar-overflow scrollbar-overflow__small"
          >
            {image.photoshop_instructions && (
              <div>
                {`Photoshop instructions: ${image.photoshop_instructions}`}
              </div>
            )}
            {image.photoshop_description && (
              <div>
                {`Photoshop description: ${image.photoshop_description}`}
              </div>
            )}
            {image.photoshop_iptc && (
              <div>
                {`Photoshop iptc: ${image.photoshop_iptc}`}
              </div>
            )}
          </div>
        )}

        {image.image_rekognition_label && (
          <div
            className="image-preview--details__label-recognition image-preview--details__border
              scrollbar-overflow scrollbar-overflow__small"
          >
            {Object.keys(image.image_rekognition_label[0]).map((key) => (
              Object.keys(image.image_rekognition_label[0][key]).map((key1) => (
                <div key={key1}>
                  {`${key1 === 'name' ? image.image_rekognition_label[0][key][key1] : ''}`}
                </div>
              ))
            ))}
          </div>
        )}

        {Object.keys(image.path).length > 0 && (
          <div className="image-preview--details__path image-preview--details__border
              scrollbar-overflow scrollbar-overflow__small"
          >
            {
              Object.keys(image.path).map((path, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="path-item" key={i}>
                  <div
                    className="path-color"
                    style={{
                      backgroundColor:
                        orderState.selectedImagePaths?.[image.id]?.[path]
                          ? getPathColor(i)
                          : null,
                    }}
                  />
                  <Checkbox
                    label={path}
                    checked={orderState.selectedImagePaths?.[image.id]?.[path]}
                    id={`${image.id}-${path}-checkbox`}
                    onChange={() => onSelectPath(path)}
                  />
                  {
                    image.path[path]?.line.viewbox && <PathOutsideImageIconSvg />
                  }
                </div>
              ))
            }
          </div>
        )}
      </div>

      {commentsLength > 0 && (
        <div className="image-preview--details image-preview--comments__wrap">
          {image.image_comments.map((imageComment, commentIndex) => (
            <Comment
              key={imageComment.id}
              comment={imageComment}
              isLast={(commentIndex + 1) === commentsLength}
              refreshGallery={refreshGallery}
              resetInterval={resetInterval}
            />
          ))}
        </div>
      )}

      <div
        className="image-preview--details__actions--wrap"
        onMouseLeave={onHandleMouseLeave}
        onMouseEnter={onHandleMouseEnter}
      >
        <div className="image-preview--details__actions">
          {isOutputImage && (
            <Tooltip
              text={isConfirmedApproved(image.status, image.image_tag_color)
                ? Translation.confirm_image : Translation.unconfirmed_image}
            >
              <div
                className={`image-preview--details__actions-action ${NOSELECT_CLASS}`}
                onClick={(e) => onHandleImageAction(
                  e,
                  userState.user.is_admin
                    ? getEndpointUrl(userState.user.role_after_login, API_ENDPOINTS_GALLERY.confirm)
                    : getEndpointUrl(userState.user.role_after_login, API_ENDPOINTS_GALLERY.approve),
                )}
              >
                {
                  // eslint-disable-next-line no-nested-ternary
                  isConfirmedApproved(image.status, image.image_tag_color)
                    ? userState.user.is_admin
                      ? <PreviewCheckSvg /> : <ActionLikeSvg className="width-13 height-12 top-1" />
                    : userState.user.is_admin
                      ? <PreviewUndoSvg className="top--2 right-4" />
                      : <ActionDislikeSvg className="width-13 height-12 top-2" />
                }
              </div>
            </Tooltip>
          )}

          {isOutputImage && (
            <Tooltip text={Translation.toggle_original_image}>
              <div
                className={`image-preview--details__actions-action ${NOSELECT_CLASS} ${isOriginalImage ? ACTIVE_CLASS : ''}`}
                onClick={toggleOriginalImage}
              >
                <PreviewImageSvg className="top-1" />
              </div>
            </Tooltip>
          )}

          <Tooltip text={Translation.download_image}>
            <div
              className={`image-preview--details__actions-action ${NOSELECT_CLASS} download`}
              onClick={onDownload}
            >
              <PreviewDownloadSvg className="top-1" />
            </div>
          </Tooltip>

          <Tooltip text={Translation.open_draggable_image}>
            <div
              className={
                `image-preview--details__actions-action ${NOSELECT_CLASS}
                ${(layoutState.isDraggableOpened && index === layoutState.draggableIndex) ? ACTIVE_CLASS : ''}`
              }
              onClick={toggleDraggable}
            >
              <PreviewDuplicateSvg className="top-1" />
            </div>
          </Tooltip>

          <div
            className={`image-preview--details__actions-action ${NOSELECT_CLASS}`}
            onClick={openBottomMenu}
          >
            <PreviewMoreSvg />
          </div>

          {isBottomMenuOpened && (
            <div className="bottom-menu">
              {isOutputImage && (
                <div
                  className="bottom-menu--item"
                  onClick={onHandleSend}
                >
                  <PreviewSendSvg />
                  {Translation.send}
                </div>
              )}

              {userState.user.is_admin && (
                <div
                  className="bottom-menu--item"
                  onClick={onHandleDelete}
                >
                  <PreviewDeleteSvg />
                  {Translation.delete}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {layoutState.isDraggableOpened && index === layoutState.draggableIndex && (
        <div
          className="draggable-wrap"
          ref={draggableImageRef}
          onMouseEnter={onHandleMouseEnterDraggable}
          onMouseLeave={onHandleMouseLeaveDraggable}
          onClick={(e) => e.stopPropagation()}
          style={{ backgroundImage: `url(${TRANSPARENT_BG})` }}
        >
          {isMouseOverDraggable && (
            <OverlayCloseIconSvg className="draggable-close" onClick={closeDraggable} />
          )}
          <img
            id={`image-draggable-${image.id}`}
            src={orderState.links[image.id]?.[IMAGE_SIZES.medium]}
            data-id={image.id}
            data-index={index}
            onLoad={handlePreviewImgLoad}
            onError={handlePreviewImgError}
            className={`image-draggable ${SKELETON_CLASS}`}
            alt=""
          />
        </div>
      )}
    </div>
  )
}

Preview.propTypes = {
  image: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Preview.defaultProps = {
  image: {},
  index: 0,
  refreshGallery: () => { },
  resetInterval: () => { },
}

export default Preview
