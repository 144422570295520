import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'
import {
  brokenFilePreview,
  notFoundPreview,
  addClass,
  debounce,
  dragElement,
  getPathColor,
  isAdminRole,
  isAltKey,
  parseFloatRound,
  removeClass,
  useEventListener,
  buildGrids,
  buildPath,
  updateVerticalGridLine,
  updateHorizontalGridLine,
  updateGrid,
} from '../../../helpers/Utils'
import {
  DEFAULT_IMAGE_SIZES,
  EMPTY_IMG,
  IMAGE_ERROR_CLASS,
  IMAGE_ERROR_CODES,
  IMAGE_SIZES,
  IMAGE_STATUSES,
  IMAGE_TYPES,
  PATH_COLORS,
  PATH_WIDTH_MIN,
  PATH_WIDTH_MAX,
  PX_TO_REM,
  SKELETON_CLASS,
  TRANSPARENT_BG,
  VERTICAL_GRID_CLASS,
  HORIZONTAL_GRID_CLASS,
  SHOW_PATH_CLASS,
  OVERLAY_IMG_CLASS,
  OVERLAY_GRID_CLASS,
  NOSELECT_CLASS,
  ACTIVE_CLASS,
  PATH_DISPLAY_RATIO,
  ALL_LAYERS_KEY,
} from '../../../helpers/Constants'

import Typography from '../../../components/Typography'
import Range from '../../../components/Range'
import Checkbox from '../../../components/Checkbox'
import Toggle from '../../../components/Toggle'
import RadioGroup from '../../../components/RadioGroup'
import DualRange from '../../../components/DualRange'

import OverlayAvatars from './OverlayAvatars'
import CommentOverlay from './CommentOverlay'
import Background from './Background'
import Shortcuts from './Shortcuts'
import CollapsibleCommentOverlay from './CollapsibleCommentOverlay'
import CollapsibleImageInfo from './CollapsibleImageInfo'

import { ReactComponent as OverlayCloseIconSvg } from '../../../svg/overlay_close_icon.svg'
import { ReactComponent as OverlayLeftArrowIconSvg } from '../../../svg/overlay_right_arrow_icon.svg'
import { ReactComponent as OverlayCompareIconSvg } from '../../../svg/overlay_compare_icon.svg'
import { ReactComponent as OverlayDrawingIconSvg } from '../../../svg/overlay_drawing_icon.svg'
import { ReactComponent as OverlayDragIconSvg } from '../../../svg/overlay_drag_icon.svg'
import { ReactComponent as OverlayMagnifyIconSvg } from '../../../svg/overlay_magnify_icon.svg'
import { ReactComponent as OverlayNextIconSvg } from '../../../svg/overlay_next_icon.svg'
import { ReactComponent as OverlayPrevIconSvg } from '../../../svg/overlay_prev_icon.svg'
import { ReactComponent as CommentIconSvg } from '../../../svg/t_logo.svg'
import { ReactComponent as EnterIconSvg } from '../../../svg/enter.svg'
import { ReactComponent as CategoryIconSvg } from '../../../svg/category.svg'
import { ReactComponent as RedoIconSvg } from '../../../svg/repeat.svg'
import { ReactComponent as RocketIconSvg } from '../../../svg/rocket.svg'
import { ReactComponent as PathOutsideImageIconSvg } from '../../../svg/path_outside_image.svg'
import { ReactComponent as LayerMaskIconSvg } from '../../../svg/layer_mask.svg'
import { ReactComponent as LayerVisibleIconSvg } from '../../../svg/layer_visible.svg'
import { ReactComponent as LayerInvisibleIconSvg } from '../../../svg/layer_invisible.svg'
import { ReactComponent as CommentDrawingOvalSvg } from '../../../svg/comment_drawing_oval.svg'
import { ReactComponent as CommentDrawingRectangleSvg } from '../../../svg/comment_drawing_rectangle.svg'
import { ReactComponent as CommentDrawingArrowSvg } from '../../../svg/comment_drawing_arrow.svg'
import { ReactComponent as CommentDrawingFreehandSvg } from '../../../svg/comment_drawing_freehand.svg'
import { ReactComponent as CommentDrawingLineSvg } from '../../../svg/comment_drawing_line.svg'

import './index.scss'

const Overlay = ({ refreshGallery, resetInterval }) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isOverlayOpened: state.layout.isOverlayOpened,
    isShortcutsOpened: state.layout.isShortcutsOpened,
    leftPanelWidth: state.layout.leftPanelWidth,
    rightPanelWidth: state.layout.rightPanelWidth,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateOverlayOpened: actions.layout.updateOverlayOpened,
    updateShortcutsOpened: actions.layout.updateShortcutsOpened,
  }))

  const orderState = useStoreState((state) => ({
    images: state.order.images,
    imageDetails: state.order.imageDetails,
    overlayImgIndex: state.order.overlayImgIndex,
    imageType: state.order.imageType,
    layerUrls: state.order.layerUrls,
    paths: state.order.paths,
    pathRange: state.order.pathRange,
    selectedBgColor: state.order.selectedBgColor,
    gallery: state.order.gallery,
    selectedImagePaths: state.order.selectedImagePaths,
    selectedGrids: state.order.selectedGrids,
    orderViewingUsers: state.order.orderViewingUsers,
    selectedLayers: state.order.selectedLayers,
    drawingColor: state.order.drawingColor,
  }))

  const orderActions = useStoreActions((actions) => ({
    setOverlayImgIndex: actions.order.setOverlayImgIndex,
    getImageDetails: actions.order.getImageDetails,
    addComment: actions.order.addComment,
    removeError: actions.order.removeError,
    getPath: actions.order.getPath,
    setPathRange: actions.order.setPathRange,
    setSelectedImagePaths: actions.order.setSelectedImagePaths,
    setSelectedGrids: actions.order.setSelectedGrids,
    setSelectedLayers: actions.order.setSelectedLayers,
  }))

  const [overlayImgSize, setOverlayImgSize] = useState(DEFAULT_IMAGE_SIZES)

  const initOverlayImgPos = { top: 0, left: 0 }

  const fullZoomValue = 100
  const [isFullZoom, setIsFullZoom] = useState(false)
  const [zoomValue, setZoomValue] = useState(fullZoomValue)

  const overlayContainer = useRef(null)
  const overlayImageWrapRef = useRef(null)
  const overlayImgRef = useRef(null)

  const dragEventRef = useRef(null)
  const commentTextareaRef = useRef(null)

  const [isNotFound, setIsNotFound] = useState(false)
  const [isBroken, setIsBroken] = useState(false)
  const [imageElements, setImageElements] = useState({})
  const [isOriginalImage, setIsOriginalImage] = useState(false)
  const [isSpaceRequired, setIsSpaceRequired] = useState(true)
  const [comment, setComment] = useState('')
  const [originalImageDetails, setOriginalImageDetails] = useState({})
  const [imagePaths, setImagePaths] = useState({})
  const [layerValues, setLayerValues] = useState({})

  const showInterfaceInit = JSON.parse(localStorage.getItem('showInterface')) !== null
    ? JSON.parse(localStorage.getItem('showInterface')) : true
  const [showInterface, setShowInterface] = useState(showInterfaceInit)

  const [isExpanded, setIsExpanded] = useState(
    localStorage.getItem('isExpanded')
      ? JSON.parse(localStorage.getItem('isExpanded'))
      : {
        imageInformation: false,
        contrast: false,
        background: false,
        grids: false,
        paths: false,
        layers: false,
        alphaChannel: false,
        history: false,
        commentSuggestion: false,
      },
  )

  useEffect(() => {
    localStorage.setItem('showInterface', showInterface)
  }, [showInterface])

  const updateGrids = () => {
    orderState.selectedGrids.forEach((selectedGrid) => {
      const gridIndex = orderState.gallery?.grids?.findIndex((grid) => grid.name === selectedGrid.name)

      document.querySelectorAll(`.${OVERLAY_GRID_CLASS}.grid-${gridIndex}`).forEach((gridElement) => {
        updateGrid(
          gridElement,
          orderState.images[orderState.overlayImgIndex].width,
          orderState.images[orderState.overlayImgIndex].height,
        )

        gridElement.querySelectorAll(`.${VERTICAL_GRID_CLASS}`).forEach((lineElement, i) => {
          updateVerticalGridLine(
            lineElement,
            orderState.gallery.grids[gridIndex].left[i],
            orderState.images[orderState.overlayImgIndex].width,
            orderState.images[orderState.overlayImgIndex].height,
          )
        })

        gridElement.querySelectorAll(`.${HORIZONTAL_GRID_CLASS}`).forEach((lineElement, i) => {
          updateHorizontalGridLine(
            lineElement,
            orderState.gallery.grids[gridIndex].top[i],
            orderState.images[orderState.overlayImgIndex].width,
            orderState.images[orderState.overlayImgIndex].height,
          )
        })
      })
    })
  }

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    setImagePaths(orderState.images[orderState.overlayImgIndex].path)
    document.querySelectorAll(`.${SHOW_PATH_CLASS}`).forEach((path) => path.remove())

    updateGrids()
  }, [layoutState.isOverlayOpened, orderState.overlayImgIndex])

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return
    setOriginalImageDetails(orderState.imageDetails[orderState.images[orderState.overlayImgIndex].original_image_id])
  }, [orderState.imageDetails])

  useEffect(() => {
    const layers = {}
    orderState.images[orderState.overlayImgIndex]?.layer?.forEach((layer) => {
      layers[layer.id] = (
        <div className="layer-item">
          {parseInt(layer.is_visible, 2) ? <LayerVisibleIconSvg /> : <LayerInvisibleIconSvg />}
          {parseInt(layer.has_layer_mask, 2) ? <LayerMaskIconSvg /> : null}
          <Typography label={layer.name.replace(/&nbsp;/g, ' ')} />
        </div>
      )
    })
    setLayerValues({
      [`${ALL_LAYERS_KEY}-${orderState.images[orderState.overlayImgIndex]?.id}`]: (
        <div className="layer-item">
          <Typography label={Translation.all_layers} />
        </div>
      ),
      ...layers,
    })
  }, [orderState.images[orderState.overlayImgIndex]])

  useEffect(() => {
    Object.keys(orderState.imageDetails).forEach((detail) => {
      if (detail) {
        if (!imageElements[detail] && overlayImgRef.current.src !== orderState.imageDetails[detail].url) {
          addClass(document.querySelector(`.${OVERLAY_IMG_CLASS}`), SKELETON_CLASS)
        }
        if (imageElements[detail] && overlayImgRef.current.src === orderState.imageDetails[detail].url) {
          removeClass(document.querySelector(`.${OVERLAY_IMG_CLASS}`), SKELETON_CLASS)
        }
        const image = new Image()
        image.src = orderState.imageDetails[detail].url
        image.onload = () => {
          setImageElements((prevImageElements) => ({
            ...prevImageElements,
            [orderState.imageDetails[detail].id]: image,
          }))
        }
        image.onerror = () => {
          if (parseInt(orderState.imageDetails[detail].status, 10) === IMAGE_STATUSES.broken) {
            image.src = brokenFilePreview
          } else {
            image.src = notFoundPreview
          }
        }
      }
    })
  }, [orderState.imageDetails])

  useEffect(() => {
    const defaultSelectedGrids = orderState.gallery?.grids?.filter((grid) => grid?.is_show)
    const newSelectedGrids = [...orderState.selectedGrids, ...defaultSelectedGrids]
    orderActions.setSelectedGrids(newSelectedGrids)
  }, [orderState.gallery?.grids?.length])

  const getDetails = (index = null, isOriginal = false) => {
    let previousImage = {}
    if (index || orderState.overlayImgIndex > 0) previousImage = orderState.images[(index || orderState.overlayImgIndex) - 1]

    let nextImage = {}
    if (index || orderState.overlayImgIndex < (orderState.images.length - 1)) {
      nextImage = orderState.images[(index || orderState.overlayImgIndex) + 1]
    }

    const outPayload = {
      image_ids: [],
      order_ids: [],
      image_type: orderState.imageType,
      image_size: IMAGE_SIZES.extraLarge,
    }

    if (previousImage.id && !orderState.imageDetails[previousImage.id]) {
      outPayload.image_ids.push(previousImage.id)
      outPayload.order_ids.push(previousImage.order_id)
    }

    if (!orderState.imageDetails[orderState.images[index || orderState.overlayImgIndex].id]) {
      outPayload.image_ids.push(orderState.images[index || orderState.overlayImgIndex].id)
      outPayload.order_ids.push(orderState.images[index || orderState.overlayImgIndex].order_id)
    }

    if (nextImage?.id && !orderState.imageDetails[nextImage.id]) {
      outPayload.image_ids.push(nextImage.id)
      outPayload.order_ids.push(nextImage.order_id)
    }

    if (outPayload.image_ids.length) orderActions.getImageDetails(outPayload)

    if (isOriginal) {
      const inPayload = {
        image_ids: [],
        order_ids: [],
        image_type: IMAGE_TYPES.input,
        image_size: IMAGE_SIZES.extraLarge,
      }

      if (previousImage.id && !orderState.imageDetails[previousImage.original_image_id]) {
        inPayload.image_ids.push(parseInt(previousImage.original_image_id, 10))
        inPayload.order_ids.push(previousImage.order_id)
      }

      if (!orderState.imageDetails[orderState.images[index || orderState.overlayImgIndex].original_image_id]) {
        inPayload.image_ids.push(parseInt(orderState.images[index || orderState.overlayImgIndex].original_image_id, 10))
        inPayload.order_ids.push(orderState.images[index || orderState.overlayImgIndex].order_id)
      }

      if (nextImage?.id && !orderState.imageDetails[nextImage.original_image_id]) {
        inPayload.image_ids.push(parseInt(nextImage.original_image_id, 10))
        inPayload.order_ids.push(nextImage.order_id)
      }

      if (inPayload.image_ids.length) orderActions.getImageDetails(inPayload)
    }
  }

  const getCenterPosition = () => {
    const pos = initOverlayImgPos

    if (overlayContainer.current?.offsetWidth > 0 && overlayContainer.current?.offsetHeight > 0) {
      const scrollLeft = parseFloatRound((overlayImgSize.width - overlayContainer.current.offsetWidth) / 2, 2)
      /* if (scrollLeft > 0) {
        overlayContainer.current.scrollLeft = scrollLeft
      } else {
        pos.left = -1 * scrollLeft
      } */

      const scrollTop = parseFloatRound((overlayImgSize.height - overlayContainer.current.offsetHeight) / 2, 2)
      /* if (scrollTop > 0) {
        overlayContainer.current.scrollTop = scrollTop
      } else {
        pos.top = -1 * scrollTop
      } */

      pos.left = -1 * scrollLeft
      pos.top = -1 * scrollTop
    }

    return pos
  }

  const getImageSizes = () => ({
    // eslint-disable-next-line no-nested-ternary
    width: isOriginalImage
      ? originalImageDetails?.width
      : (orderState.images[orderState.overlayImgIndex]?.width)
        ? orderState.images[orderState.overlayImgIndex]?.width
        : DEFAULT_IMAGE_SIZES.width,
    // eslint-disable-next-line no-nested-ternary
    height: isOriginalImage
      ? originalImageDetails?.height
      : (orderState.images[orderState.overlayImgIndex]?.height)
        ? orderState.images[orderState.overlayImgIndex]?.height
        : DEFAULT_IMAGE_SIZES.height,
  })

  const getResizeRatio = () => {
    // console.log('getResizeRatio', 'zoomValue', zoomValue, 'isFullZoom', isFullZoom)
    let ratio = 1

    if (isFullZoom) return ratio

    if (overlayContainer.current?.offsetWidth > 0 && overlayContainer.current?.offsetHeight > 0) {
      const imageSizes = getImageSizes()

      const ratioW = overlayContainer.current.offsetWidth / imageSizes.width
      const ratioH = overlayContainer.current.offsetHeight / imageSizes.height

      ratio = (ratioW > ratioH) ? ratioH : ratioW
      if (ratio > 1) ratio = 1
    }

    return ratio
  }

  const positionToCenter = (shiftTop = 0, shiftLeft = 0) => {
    const pos = getCenterPosition()
    overlayImageWrapRef.current.style.top = isBroken ? '0' : `${pos.top + shiftTop}px`
    overlayImageWrapRef.current.style.left = isBroken ? '0' : `${pos.left + shiftLeft}px`
  }

  const resizeLayers = () => {
    // console.log('resizeLayers', 'zoomValue', zoomValue, 'isFullZoom', isFullZoom)
    const ratio = getResizeRatio()

    const imageSizes = getImageSizes()

    if (!isFullZoom) {
      setOverlayImgSize({
        width: parseFloatRound(imageSizes.width * ratio, 2),
        height: parseFloatRound(imageSizes.height * ratio, 2),
      })
    } else {
      setOverlayImgSize(imageSizes)
    }

    setZoomValue(Math.round(ratio * fullZoomValue))
  }

  const handleOverlayZoom = () => {
    // console.log('handleOverlayZoom', 'zoomValue', zoomValue, 'isFullZoom', isFullZoom)
    setIsFullZoom(!isFullZoom)
  }

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    resizeLayers()
  }, [orderState.overlayImgIndex, isFullZoom])

  const resizeLayersOnWindowResize = debounce(() => resizeLayers())

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return () => { }

    window.addEventListener('resize', resizeLayersOnWindowResize)

    getDetails()

    dragEventRef.current = dragElement(overlayImageWrapRef.current, isSpaceRequired)
    overlayImageWrapRef.current.addEventListener('mousedown', dragEventRef.current)

    // cleanup: "remove event listeners" and "intervals" to avoid memory leaks by creating the same listeners
    return () => {
      window.removeEventListener('resize', resizeLayersOnWindowResize)
    }
  }, [layoutState.isOverlayOpened])

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    positionToCenter()

    document.querySelectorAll(`.${SHOW_PATH_CLASS}`).forEach((path) => {
      path?.setAttribute('style', `width: ${overlayImgSize.width}px; height: ${overlayImgSize.height}px;`)
    })

    document.querySelectorAll(`.${OVERLAY_GRID_CLASS}`).forEach((grid) => {
      grid?.setAttribute('style', `width: ${overlayImgSize.width}px; height: ${overlayImgSize.height}px;`)
    })
  }, [overlayImgSize])

  useEffect(() => {
    if (overlayImgRef.current?.src?.includes(notFoundPreview)) {
      setIsNotFound(true)
    } else if (overlayImgRef.current?.src?.includes(brokenFilePreview)) {
      setIsBroken(true)
    } else {
      setIsNotFound(false)
      setIsBroken(false)
    }
  }, [overlayImgRef.current?.src])

  const handleOverlayImgLoad = (e) => {
    const previewImg = e.currentTarget
    if (previewImg.src !== `${window.location.origin}${EMPTY_IMG}`) {
      removeClass(previewImg, SKELETON_CLASS)
    }
  }

  const handleOverlayImgLoadError = (e) => {
    const previewImg = e.currentTarget
    if (
      orderState.selectedLayers[orderState.images[orderState.overlayImgIndex]?.id]
      !== `${ALL_LAYERS_KEY}-${orderState.images[orderState.overlayImgIndex]?.id}`
    ) {
      removeClass(previewImg, SKELETON_CLASS)
      document.querySelector(`.${OVERLAY_IMG_CLASS}`).src = notFoundPreview
    }
  }

  const handleNextPrevClick = async (e) => {
    const { direction } = e.currentTarget.dataset
    if (direction === '-1' && orderState.overlayImgIndex === 0) return
    if (direction === '1' && orderState.overlayImgIndex === orderState.images.length - 1) return
    orderActions.setOverlayImgIndex(orderState.overlayImgIndex + parseInt(direction, 10))
    getDetails(orderState.overlayImgIndex + parseInt(direction, 10), isOriginalImage)
  }

  const onCloseOverlay = () => {
    layoutActions.updateOverlayOpened(false)
  }

  useEventListener('keyup', (e) => {
    if (!layoutState.isOverlayOpened) return

    if (e.target.tagName === 'TEXTAREA') return

    if (e.key === 'ArrowLeft') {
      if (orderState.overlayImgIndex > 0) {
        handleNextPrevClick({ currentTarget: { dataset: { direction: '-1' } } }).then(() => { })
      }
    } else if (e.key === 'ArrowRight') {
      if (orderState.overlayImgIndex < orderState.images.length - 1) {
        handleNextPrevClick({ currentTarget: { dataset: { direction: '1' } } }).then(() => { })
      }
    } else if (e.key === 'Escape') {
      onCloseOverlay()
    }
  })

  const toggleSpaceRequired = () => {
    setIsSpaceRequired(!isSpaceRequired)

    overlayImageWrapRef?.current.removeEventListener('mousedown', dragEventRef.current)
    dragEventRef.current = dragElement(overlayImageWrapRef.current, !isSpaceRequired)
    overlayImageWrapRef.current.addEventListener('mousedown', dragEventRef.current)
  }

  const toggleOriginalImage = () => {
    if (orderState.images[orderState.overlayImgIndex].original_image_id) {
      addClass(document.querySelector(`.${OVERLAY_IMG_CLASS}`), SKELETON_CLASS)
      setIsOriginalImage(!isOriginalImage)
      getDetails(orderState.overlayImgIndex, true)
    }
  }

  useEffect(() => {
    if (originalImageDetails?.width && originalImageDetails?.height) {
      resizeLayers()
    }
  }, [isOriginalImage, originalImageDetails])

  const handleCommentLogoClick = () => {
    commentTextareaRef.current.focus()
  }

  const handleAddComment = async () => {
    if (comment) {
      const res = await orderActions.addComment({
        role_after_login: userState.user.role_after_login,
        body: {
          image_ids: [orderState.images[orderState.overlayImgIndex].id],
          comment,
          is_reply: false,
        },
      })

      if (res) {
        refreshGallery()
        resetInterval()
        setComment('')
      }
    }
  }

  const onCommentSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddComment().then(() => { })
    }
  }

  const expand = (key) => {
    setIsExpanded(() => ({
      ...isExpanded,
      [key]: !isExpanded[key],
    }))

    localStorage.setItem('isExpanded', JSON.stringify(
      {
        ...isExpanded,
        [key]: !isExpanded[key],
      },
    ))
  }

  const removeErrorCode = async (e, errorCode) => {
    if (!isAdminRole(userState.user.role_after_login)) return

    if (isAltKey(e)) {
      e.stopPropagation()

      if (errorCode === IMAGE_ERROR_CODES.wrongFileName) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (!orderState.images[orderState.overlayImgIndex].wrong_format) {
          removeClass(e.currentTarget.parentElement, 'info-error')
        }
      } else if (errorCode === IMAGE_ERROR_CODES.wrongFormat) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (!orderState.images[orderState.overlayImgIndex].wrong_file_name) {
          removeClass(e.currentTarget.parentElement, 'info-error')
        }
      } else if (errorCode === IMAGE_ERROR_CODES.colorSpaceError || errorCode === IMAGE_ERROR_CODES.noBgColor) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        removeClass(e.currentTarget.parentElement, 'info-error')
      } else if (errorCode === IMAGE_ERROR_CODES.widthError) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (
          !orderState.images[orderState.overlayImgIndex].height_error
          && !orderState.images[orderState.overlayImgIndex].dpi_error
        ) {
          removeClass(e.currentTarget.parentElement, 'info-error')
        }
      } else if (errorCode === IMAGE_ERROR_CODES.heightError) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (
          !orderState.images[orderState.overlayImgIndex].width_error
          && !orderState.images[orderState.overlayImgIndex].dpi_error
        ) {
          removeClass(e.currentTarget.parentElement, 'info-error')
        }
      } else if (errorCode === IMAGE_ERROR_CODES.dpiError) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (
          !orderState.images[orderState.overlayImgIndex].width_error
          && !orderState.images[orderState.overlayImgIndex].height_error
        ) {
          removeClass(e.currentTarget.parentElement, 'info-error')
        }
      } else {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
      }

      const res = await orderActions.removeError({
        role_after_login: userState.user.role_after_login,
        body: {
          image_ids: [orderState.images[orderState.overlayImgIndex].id],
          error_codes: [errorCode],
        },
      })
      if (res) {
        refreshGallery()
        resetInterval()
      }
    }
  }

  const onChangePathRange = (value) => {
    orderActions.setPathRange(parseInt(value, 10))
  }

  const onSelectPath = async (path) => {
    const selectedImagePathsCopy = { ...orderState.selectedImagePaths }
    if (!selectedImagePathsCopy[orderState.images[orderState.overlayImgIndex].id]) {
      selectedImagePathsCopy[orderState.images[orderState.overlayImgIndex].id] = {}
    }
    selectedImagePathsCopy[orderState.images[orderState.overlayImgIndex].id][path] = !selectedImagePathsCopy[
      orderState.images[orderState.overlayImgIndex].id
    ][path]
    orderActions.setSelectedImagePaths(selectedImagePathsCopy)

    if (!selectedImagePathsCopy?.[orderState.images[orderState.overlayImgIndex].id]?.[path]) {
      document.getElementById(`${orderState.images[orderState.overlayImgIndex].id}-${path}`)?.remove()
      document.getElementById(`${orderState.images[orderState.overlayImgIndex].id}-${path}-preview`)?.remove()
      return
    }
    if (orderState.paths[orderState.images[orderState.overlayImgIndex].id]?.[imagePaths[path]?.line.s3_path]) {
      return
    }

    await orderActions.getPath({
      image_ids: [
        orderState.images[orderState.overlayImgIndex].id,
      ],
      s3_paths: [
        imagePaths[path]?.line.s3_path,
      ],
    })
  }

  const addPath = (path) => {
    const elementString = orderState.paths[orderState.images[orderState.overlayImgIndex].id]
      ?.[orderState.images[orderState.overlayImgIndex].path?.[path]?.line?.s3_path]
    if (!elementString || document.getElementById(`${orderState.images[orderState.overlayImgIndex].id}-${path}`)) return

    const pathColorIndex = Object.keys(
      orderState.images[orderState.overlayImgIndex].path,
    ).map((key) => key).findIndex((value) => value === path)

    const svgElement = buildPath(
      `${orderState.images[orderState.overlayImgIndex].id}-${path}`,
      SHOW_PATH_CLASS,
      elementString,
      overlayImgSize.width,
      overlayImgSize.height,
      pathColorIndex,
      orderState.pathRange,
    )
    document.querySelector(`.${OVERLAY_IMG_CLASS}`).insertAdjacentElement('afterend', svgElement)
  }

  const onSelectGrid = (grid) => {
    const newSelectedGrids = [...orderState.selectedGrids]
    const index = newSelectedGrids.findIndex((selectedGrid) => selectedGrid.name === grid.name)
    if (index > -1) {
      newSelectedGrids.splice(index, 1)
      document
        .querySelector(`.${OVERLAY_GRID_CLASS}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`)
        ?.remove()
      document
        .querySelectorAll(`.${OVERLAY_GRID_CLASS}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`)
        ?.forEach((gridElement) => {
          gridElement?.remove()
        })
    } else {
      newSelectedGrids.push(grid)
    }
    orderActions.setSelectedGrids(newSelectedGrids)
  }

  const toggleInterface = () => {
    setShowInterface((prev) => !prev)

    setTimeout(() => {
      // resizeLayers()
      if (showInterface) {
        positionToCenter(0, (layoutState.leftPanelWidth - layoutState.rightPanelWidth) / 2)
      } else {
        positionToCenter()
      }
    }, 10)
  }

  const toggleShortcuts = () => {
    layoutActions.updateShortcutsOpened(!layoutState.isShortcutsOpened)
  }

  const sendInRedo = () => {
    // TODO: add send in Redo logic
  }

  const onHandleLayerChange = (e) => {
    e.preventDefault()
    e.stopPropagation()
    orderActions.setSelectedLayers({
      imageId: orderState.images[orderState.overlayImgIndex].id,
      layerId: e.target.value,
    })
  }

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    orderState.selectedGrids.forEach((grid) => {
      if (document
        .querySelector(`.${OVERLAY_GRID_CLASS}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`)
      ) return

      const color = PATH_COLORS[orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)]
      const ratio = getResizeRatio()

      const svgElement = buildGrids(
        grid,
        orderState.gallery?.grids,
        orderState.images[orderState.overlayImgIndex].width,
        orderState.images[orderState.overlayImgIndex].height,
        parseFloatRound(orderState.images[orderState.overlayImgIndex].width * ratio, 2),
        parseFloatRound(orderState.images[orderState.overlayImgIndex].height * ratio, 2),
        color,
        OVERLAY_GRID_CLASS,
      )

      document.querySelector(`.${OVERLAY_IMG_CLASS}`).insertAdjacentElement('afterend', svgElement)
    })
  }, [orderState.selectedGrids])

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return
    if (!orderState.paths[orderState.images[orderState.overlayImgIndex]?.id]) return
    Object.keys(orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex]?.id] || {})
      .forEach((path) => {
        if (orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex]?.id]?.[path]) {
          addPath(path)
        }
      })
  }, [
    orderState.paths,
    orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex]?.id],
    layoutState.isOverlayOpened,
  ])

  useEffect(() => {
    document.querySelectorAll(`.${SHOW_PATH_CLASS}`).forEach((path) => {
      path?.querySelector('path').setAttribute('stroke-width', orderState.pathRange / PATH_DISPLAY_RATIO)
    })
  }, [orderState.pathRange])

  const fileName = () => (
    <div
      className={
        (
          isOriginalImage
            ? (originalImageDetails?.wrong_file_name || originalImageDetails?.wrong_format)
            : (orderState.images[orderState.overlayImgIndex].wrong_file_name
              || orderState.images[orderState.overlayImgIndex].wrong_format)
        )
          ? 'info info-error'
          : 'info'
      }
    >
      <span
        className={
          (isOriginalImage
            ? originalImageDetails?.wrong_file_name
            : orderState.images[orderState.overlayImgIndex].wrong_file_name)
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.wrongFileName)}
      >
        {
          isOriginalImage
            ? originalImageDetails?.name_without_format
            : orderState.images[orderState.overlayImgIndex].name_without_format
        }
      </span>
      <span>&nbsp;.&nbsp;</span>
      <span
        className={
          (isOriginalImage
            ? originalImageDetails?.wrong_format
            : orderState.images[orderState.overlayImgIndex].wrong_format)
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.wrongFormat)}
      >
        {
          isOriginalImage
            ? originalImageDetails?.format
            : orderState.images[orderState.overlayImgIndex].format
        }
      </span>
    </div>
  )

  const showFileName = () => {
    if (isExpanded.imageInformation) {
      return true
    }

    const imageDetails = isOriginalImage ? originalImageDetails : orderState.images[orderState.overlayImgIndex]
    return imageDetails?.wrong_file_name || imageDetails?.wrong_format
  }

  const imageDimensions = () => (
    <div
      className={
        (
          isOriginalImage
            ? (originalImageDetails?.width_error
              || originalImageDetails?.height_error
              || originalImageDetails?.dpi_error)
            : (orderState.images[orderState.overlayImgIndex].width_error
              || orderState.images[orderState.overlayImgIndex].height_error
              || orderState.images[orderState.overlayImgIndex].dpi_error)
        )
          ? 'info info-error'
          : 'info'
      }
    >
      <span
        className={
          (isOriginalImage
            ? originalImageDetails?.width_error
            : orderState.images[orderState.overlayImgIndex].width_error)
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.widthError)}
      >
        {
          isOriginalImage
            ? originalImageDetails?.width
            : orderState.images[orderState.overlayImgIndex].width
        }
      </span>
      <span>&nbsp;x&nbsp;</span>
      <span
        className={
          (isOriginalImage
            ? originalImageDetails?.height_error
            : orderState.images[orderState.overlayImgIndex].height_error
          )
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.heightError)}
      >
        {isOriginalImage
          ? originalImageDetails?.height
          : orderState.images[orderState.overlayImgIndex].height}
      </span>
      <span>
        &nbsp;
        {Translation.px}
        &nbsp;
        /
        &nbsp;
      </span>
      <span
        className={
          (isOriginalImage
            ? originalImageDetails?.dpi_error
            : orderState.images[orderState.overlayImgIndex].dpi_error)
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.dpiError)}
      >
        {
          isOriginalImage
            ? originalImageDetails?.dpi
            : orderState.images[orderState.overlayImgIndex].dpi
        }
      </span>
      <span>
        &nbsp;
        {Translation.dpi}
        &nbsp;
        /
        &nbsp;
        {
          isOriginalImage
            ? originalImageDetails?.size_kb
            : orderState.images[orderState.overlayImgIndex].size_kb
        }
        &nbsp;
        {Translation.kb}
      </span>
    </div>
  )

  const showImageDimensions = () => {
    if (isExpanded.imageInformation) {
      return true
    }

    const imageDetails = isOriginalImage ? originalImageDetails : orderState.images[orderState.overlayImgIndex]
    return imageDetails?.width_error || imageDetails?.height_error || imageDetails?.dpi_error
  }

  const colorSpace = () => (
    <div
      className={
        (
          isOriginalImage
            ? originalImageDetails?.color_space_error
            : orderState.images[orderState.overlayImgIndex].color_space_error
        )
          ? 'info info-error'
          : 'info'
      }
    >
      <span
        className={
          (
            isOriginalImage
              ? originalImageDetails?.color_space_error
              : orderState.images[orderState.overlayImgIndex].color_space_error
          )
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.colorSpaceError)}
      >
        {
          isOriginalImage
            ? originalImageDetails?.colour_space
            : orderState.images[orderState.overlayImgIndex].colour_space
        }
      </span>
    </div>
  )

  const showColorSpace = () => {
    if (isExpanded.imageInformation) {
      return true
    }

    const imageDetails = isOriginalImage ? originalImageDetails : orderState.images[orderState.overlayImgIndex]
    return imageDetails?.color_space_error
  }

  const backgroundColor = () => (
    <div
      className={
        (
          isOriginalImage
            ? originalImageDetails?.no_bg_color
            : orderState.images[orderState.overlayImgIndex].no_bg_color
        )
          ? 'info info-error'
          : 'info'
      }
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {(isOriginalImage
        ? originalImageDetails?.no_bg_color
        : orderState.images[orderState.overlayImgIndex].no_bg_color)
        ? (
          <span
            className={
              (isOriginalImage
                ? originalImageDetails?.no_bg_color
                : orderState.images[orderState.overlayImgIndex].no_bg_color
              )
                ? IMAGE_ERROR_CLASS
                : ''
            }
            onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.noBgColor)}
          >
            {
              (
                isOriginalImage
                  ? originalImageDetails?.background_color
                  : orderState.images[orderState.overlayImgIndex].background_color
              )
                ? `
                ${Translation.detected_background}: ${(isOriginalImage
                  ? originalImageDetails?.background_color
                  : orderState.images[orderState.overlayImgIndex].background_color
                )}
                `
                : Translation.background_colour_not_detected
            }
          </span>
        ) : (
          isOriginalImage
            ? originalImageDetails?.background_color
            : orderState.images[orderState.overlayImgIndex].background_color
        )
          ? `
          ${Translation.detected_background}: ${(isOriginalImage
            ? originalImageDetails?.background_color
            : orderState.images[orderState.overlayImgIndex].background_color
          )}
          `
          : Translation.background_colour_not_detected}
    </div>
  )

  const showBackgroundColor = () => {
    if (isExpanded.imageInformation) {
      return true
    }

    const imageDetails = isOriginalImage ? originalImageDetails : orderState.images[orderState.overlayImgIndex]
    return imageDetails?.no_bg_color
  }

  return (
    <div
      id="image-overlay"
      className="image-overlay"
      data-is-overlay="1"
      data-allowed-to-comment="1"
      data-is-shapes="1"
      style={(layoutState.isOverlayOpened) ? {} : { display: 'none' }}
    >
      <div
        className="image-overlay--left"
        style={{
          display: showInterface ? 'flex' : 'none',
        }}
      >
        <div className="image-overlay--left__top scrollbar-overflow">
          <div
            className={`image-overlay--left__title ${isExpanded.imageInformation ? 'expanded' : ''}`}
            onClick={() => expand('imageInformation')}
          >
            <div className="image-overlay__title-text">
              {Translation.image_information}
              <OverlayLeftArrowIconSvg
                className={`title-icon 
                  ${isExpanded.imageInformation ? 'expanded' : ''}
                  ${(!isExpanded.imageInformation && (showFileName() || showImageDimensions()
                    || showColorSpace() || showBackgroundColor())) ? 'notice' : ''}`}
              />
            </div>
          </div>

          {(isExpanded.imageInformation || showFileName() || showImageDimensions()
            || showColorSpace() || showBackgroundColor()) && (
            <div
              className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small word-break"
            >
              {isExpanded.imageInformation && (
                <Typography
                  label={
                    `${orderState.images[orderState.overlayImgIndex].original_image_id
                      ? orderState.images[orderState.overlayImgIndex].original_image_id
                      : orderState.images[orderState.overlayImgIndex].id}`
                  }
                />
              )}

              {showFileName() && fileName()}

              {showImageDimensions() && imageDimensions()}

              {showColorSpace() && colorSpace()}

              {showBackgroundColor() && backgroundColor()}

              {isExpanded.imageInformation && (
                <Typography
                  label={
                    `${Translation.colour_depth}: 
                      ${isOriginalImage
                      ? originalImageDetails?.colour_depth
                      : orderState.images[orderState.overlayImgIndex].colour_depth}
                    ${Translation.bits}`
                  }
                />
              )}
            </div>
          )}

          <div
            className={`image-overlay--left__title ${isExpanded.contrast ? 'expanded' : ''}`}
            onClick={() => expand('contrast')}
          >
            <div className="image-overlay__title-text">
              {Translation.contrast}
              <OverlayLeftArrowIconSvg
                className={`title-icon ${isExpanded.contrast ? 'expanded' : 'title-icon'}`}
              />
            </div>
          </div>

          {isExpanded.contrast && (
            <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
              <DualRange
                max={100}
                min={0}
                onChange={() => { }}
                leftLabel={Translation.shadows}
                rightLabel={Translation.lights}
              />
            </div>
          )}

          <div
            className={`image-overlay--left__title ${isExpanded.background ? 'expanded' : ''}`}
            onClick={() => expand('background')}
          >
            <div className="image-overlay__title-text">
              {Translation.background}
              <OverlayLeftArrowIconSvg
                className={`title-icon ${isExpanded.background ? 'expanded' : 'title-icon'}`}
              />
            </div>
          </div>

          {isExpanded.background && (
            <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
              <Background />
            </div>
          )}

          <div
            className={`image-overlay--left__title ${isExpanded.grids ? 'expanded' : ''}`}
            onClick={() => expand('grids')}
          >
            <div className="image-overlay__title-text">
              {Translation.grids}
              <OverlayLeftArrowIconSvg
                className={`title-icon ${isExpanded.grids ? 'expanded' : 'title-icon'}`}
              />
            </div>
          </div>

          {isExpanded.grids && (
            <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
              <div className="grid-list-container">
                {orderState.gallery?.grids?.length > 0 && (
                  orderState.gallery?.grids.map((grid, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="grid-list-item" key={index}>
                      <div
                        className="grid-item-color"
                        style={{
                          backgroundColor:
                            orderState.selectedGrids.some((selectedGrid) => selectedGrid.name === grid.name)
                              ? getPathColor(index)
                              : null,
                        }}
                      />
                      <Checkbox
                        label={
                          (
                            <span className="overflow-ellipsis">
                              {grid.name || grid.name_short}
                              {' '}
                              {grid.template_name && (
                                <span className="grid-item-template-name">{grid.template_name}</span>
                              )}
                            </span>
                          )
                        }
                        id={`grid-overlay-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`}
                        checked={orderState.selectedGrids.some((selectedGrid) => selectedGrid.name === grid.name)}
                        onChange={() => onSelectGrid(grid)}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          )}

          {Object.keys(orderState.images[orderState.overlayImgIndex].path).length > 0 && (
            <>
              <div
                className={`image-overlay--left__title ${isExpanded.paths ? 'expanded' : ''}`}
                onClick={() => expand('paths')}
              >
                <div className="image-overlay__title-text">
                  {Translation.paths}
                  <OverlayLeftArrowIconSvg
                    className={`title-icon ${isExpanded.paths ? 'expanded' : 'title-icon'}`}
                  />
                </div>
              </div>

              {isExpanded.paths && (
                <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
                  <Typography
                    fontSize={PX_TO_REM['12']}
                    label={Translation.path_width}
                  />
                  <Range
                    value={orderState.pathRange}
                    min={PATH_WIDTH_MIN}
                    max={PATH_WIDTH_MAX}
                    showLabels
                    onChange={(e) => onChangePathRange(e.target.value)}
                  />

                  <div className="path-list">
                    {
                      Object.keys(imagePaths).map((path, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className="path-item" key={index}>
                          <div
                            className="path-color"
                            style={{
                              backgroundColor:
                                orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex].id]?.[path]
                                  ? getPathColor(index)
                                  : null,
                            }}
                          />
                          <Checkbox
                            label={path}
                            checked={orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex].id]?.[path]}
                            id={path}
                            onChange={() => onSelectPath(path)}
                          />
                          {
                            imagePaths[path]?.line.viewbox && <PathOutsideImageIconSvg />
                          }
                        </div>
                      ))
                    }

                  </div>
                </div>
              )}
            </>
          )}

          {orderState.images[orderState.overlayImgIndex]?.layer?.length > 0 && (
            <>
              <div
                className={`image-overlay--left__title ${isExpanded.layers ? 'expanded' : ''}`}
                onClick={() => expand('layers')}
              >
                <div className="image-overlay__title-text">
                  {Translation.layers}
                  <OverlayLeftArrowIconSvg
                    className={`title-icon ${isExpanded.layers ? 'expanded' : 'title-icon'}`}
                  />
                </div>
              </div>

              {isExpanded.layers && (
                <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
                  <div className="layers-container">
                    <RadioGroup
                      values={layerValues}
                      onChange={onHandleLayerChange}
                      value={orderState.selectedLayers[orderState.images[orderState.overlayImgIndex].id]}
                    />
                  </div>
                </div>
              )}
            </>
          )}

          <div
            className={`image-overlay--left__title ${isExpanded.alphaChannel ? 'expanded' : ''}`}
            onClick={() => expand('alphaChannel')}
          >
            <div className="image-overlay__title-text">
              {Translation.alpha_channel}
              <OverlayLeftArrowIconSvg
                className={`title-icon ${isExpanded.alphaChannel ? 'expanded' : 'title-icon'}`}
              />
            </div>
          </div>

          {isExpanded.alphaChannel && (
            <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
              <div className="alpha-channel-list">
                <div className="alpha-channel-item">
                  <Toggle
                    checked={false}
                    onChange={() => { }}
                    id="alpha-chanel-1"
                  />
                  <LayerMaskIconSvg />
                  <Typography label="Layer name" />
                </div>
                <div className="alpha-channel-item">
                  <Toggle
                    checked
                    onChange={() => { }}
                    id="alpha-chanel-2"
                  />
                  <LayerMaskIconSvg />
                  <Typography label="Layer name" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="image-overlay--middle"
        ref={overlayContainer}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {isAdminRole(userState.user.role_after_login) && orderState?.orderViewingUsers?.length ? (
          showInterface ? <OverlayAvatars /> : null
        ) : null}
        <div
          id="image-overlay--image__wrap"
          ref={overlayImageWrapRef}
          className="image-overlay--image__wrap"
          style={{
            width: `${overlayImgSize.width}px`,
            height: `${overlayImgSize.height}px`,
            ...(isBroken && {
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
            }),
            ...(orderState.selectedBgColor
              ? { backgroundColor: orderState.selectedBgColor }
              : { backgroundImage: `url(${TRANSPARENT_BG})` }),
          }}
        >
          <img
            id={OVERLAY_IMG_CLASS}
            ref={overlayImgRef}
            src={
              // eslint-disable-next-line no-nested-ternary
              isOriginalImage
                ? imageElements?.[orderState.images[orderState.overlayImgIndex]?.original_image_id]?.src
                // eslint-disable-next-line no-nested-ternary
                : (imageElements?.[orderState.images[orderState.overlayImgIndex]?.id]?.src
                  ? ((orderState.selectedLayers[orderState.images[orderState.overlayImgIndex]?.id]
                    === `${ALL_LAYERS_KEY}-${orderState.images[orderState.overlayImgIndex]?.id}`
                    || !orderState.selectedLayers[orderState.images[orderState.overlayImgIndex]?.id])
                    ? imageElements?.[orderState.images[orderState.overlayImgIndex]?.id]?.src
                    : orderState.layerUrls?.[orderState.images[orderState.overlayImgIndex].id
                    ]?.[orderState.selectedLayers[orderState.images[orderState.overlayImgIndex]?.id]])
                  : EMPTY_IMG)
            }
            onLoad={handleOverlayImgLoad}
            onError={handleOverlayImgLoadError}
            className={`${OVERLAY_IMG_CLASS} ${SKELETON_CLASS} ${NOSELECT_CLASS}`}
            style={{
              width: `${overlayImgSize.width}px`,
              height: `${overlayImgSize.height}px`,
              ...(isBroken && {
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
              }),
              ...(isNotFound && {
                backgroundColor: 'transparent',
              }),
            }}
            alt=""
          />

          <svg
            width={orderState.images[orderState.overlayImgIndex]?.width}
            height={orderState.images[orderState.overlayImgIndex]?.height}
            viewBox={`0 0 
              ${orderState.images[orderState.overlayImgIndex]?.width} 
              ${orderState.images[orderState.overlayImgIndex]?.height}`}
            className="image-overlay--guide"
            style={{
              width: `${overlayImgSize.width}px`,
              height: `${overlayImgSize.height}px`,
            }}
          />

          <canvas
            id="image-overlay--canvas__drawing"
            width={overlayImgSize.width}
            height={overlayImgSize.height}
            style={{
              width: `${overlayImgSize.width}px`,
              height: `${overlayImgSize.height}px`,
            }}
          />
        </div>
      </div>

      <div
        className="image-overlay--right"
        style={{
          display: showInterface ? 'flex' : 'none',
        }}
      >
        <div className="image-overlay--navigation">
          <div className="image-overlay--navigation__wrap">
            {orderState.imageType !== IMAGE_TYPES.input && (
              <div
                id="image-overlay__compare"
                className={
                  `image-overlay__control image-overlay__compare ${NOSELECT_CLASS} ${isOriginalImage ? ACTIVE_CLASS : ''}`
                }
                onClick={toggleOriginalImage}
              >
                <OverlayCompareIconSvg />
              </div>
            )}
            <div
              id="image-overlay__zoom"
              onClick={handleOverlayZoom}
              className={`image-overlay__control image-overlay__zoom ${NOSELECT_CLASS}`}
            >
              {zoomValue}
              %
            </div>
          </div>

          <div className="image-overlay--navigation__wrap">
            <div id="image-overlay__drawing" className={`image-overlay__control image-overlay__drawing ${NOSELECT_CLASS}`}>
              <OverlayDrawingIconSvg />
            </div>

            <div
              id="image-overlay__drag"
              className={`image-overlay__control image-overlay__drag ${NOSELECT_CLASS} ${!isSpaceRequired ? ACTIVE_CLASS : ''}`}
              onClick={toggleSpaceRequired}
            >
              <OverlayDragIconSvg />
            </div>

            <div id="image-overlay__magnify" className={`image-overlay__control image-overlay__magnify ${NOSELECT_CLASS}`}>
              <OverlayMagnifyIconSvg />
            </div>
          </div>

          <div className="image-overlay--navigation__wrap">
            <div
              id="image-overlay__prev"
              onClick={handleNextPrevClick}
              data-direction="-1"
              className={`image-overlay__control image-overlay__prev 
                ${NOSELECT_CLASS} ${(orderState.overlayImgIndex === 0) ? 'disabled' : ''}`}
            >
              <OverlayPrevIconSvg />
            </div>

            <div
              id="image-overlay__next"
              onClick={handleNextPrevClick}
              data-direction="1"
              className={
                `image-overlay__control image-overlay__next
                  ${NOSELECT_CLASS} ${(orderState.overlayImgIndex === (orderState.images?.length || 0) - 1) ? 'disabled' : ''}`
              }
            >
              <OverlayNextIconSvg />
            </div>
          </div>

          <div className="image-overlay--images-counter">
            <span id="image-overlay--images-counter__current">{(orderState.overlayImgIndex + 1)}</span>
            <span className="image-overlay--images-counter__separator">/</span>
            <span id="image-overlay--images-counter__total">{orderState.images?.length}</span>
          </div>
        </div>
        <div className="image-overlay--right__top scrollbar-overflow">
          <div
            className={`image-overlay--left__title ${isExpanded.history ? 'expanded' : ''}`}
            onClick={() => expand('history')}
          >
            <div className="image-overlay__title-text">
              {Translation.history}
              <OverlayLeftArrowIconSvg
                className={`title-icon ${isExpanded.history ? 'expanded' : 'title-icon'}`}
              />
            </div>
          </div>

          {isExpanded.history && (
            <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
              <Typography label={Translation.history} />
              <Typography label={Translation.history} />
              <Typography label={Translation.history} />
            </div>
          )}

          <div
            className={`image-overlay--left__title ${isExpanded.commentSuggestion ? 'expanded' : ''}`}
            onClick={() => expand('commentSuggestion')}
          >
            <div className="image-overlay__title-text">
              {Translation.comment_suggestions}
              <OverlayLeftArrowIconSvg
                className={`title-icon ${isExpanded.commentSuggestion ? 'expanded' : 'title-icon'}`}
              />
            </div>
          </div>

          {isExpanded.commentSuggestion && (
            <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
              <Typography label={Translation.comment_suggestions} />
              <Typography label={Translation.comment_suggestions} />
              <Typography label={Translation.comment_suggestions} />
            </div>
          )}

          {(orderState.images[orderState.overlayImgIndex]
            && orderState.images[orderState.overlayImgIndex].image_comments.length > 0)
            && (
              <>
                <div className="image-overlay--left__title image-overlay--left__title--fixed">
                  <div className="image-overlay__title-text">
                    {Translation.active_comments}
                  </div>
                </div>

                {orderState.images[orderState.overlayImgIndex].image_comments.map((imageComment) => (
                  <CommentOverlay key={imageComment.id} comment={imageComment} />
                ))}
              </>
            )}
        </div>

        <div className="comment-section">
          <textarea
            placeholder={`${Translation.leave_your_comment}...`}
            className="comment-textarea"
            ref={commentTextareaRef}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={onCommentSubmit}
            {...(comment && { style: { textIndent: 0 } })}
          />

          {!comment && (
            <CommentIconSvg className="comment-icon" onClick={handleCommentLogoClick} />
          )}

          <div className="comment-section__bottom">
            <div className="comment-section__styles">
              <button
                type="button"
                label="style"
                className="comment-section__style-button"
                style={{ backgroundColor: orderState.drawingColor }}
              />
              <button type="button" label="style" className="comment-section__style-button">
                <CommentDrawingOvalSvg />
              </button>
              <button type="button" label="style" className="comment-section__style-button">
                <CommentDrawingRectangleSvg />
              </button>
              <button type="button" label="style" className="comment-section__style-button">
                <CommentDrawingArrowSvg />
              </button>
              <button type="button" label="style" className="comment-section__style-button">
                <CommentDrawingFreehandSvg />
              </button>
              <button type="button" label="style" className="comment-section__style-button">
                <CommentDrawingLineSvg />
              </button>
            </div>
            <button
              className="comment-section__button"
              type="button"
              onClick={handleAddComment}
            >
              {Translation.add_comment}
              <EnterIconSvg />
            </button>
          </div>
        </div>
      </div>

      <div onClick={onCloseOverlay} id="image-overlay__close" className="image-overlay__close">
        <OverlayCloseIconSvg />
      </div>

      {layoutState.isShortcutsOpened && (
        <Shortcuts />
      )}

      <div className={`image-overlay--left__bottom ${!showInterface ? 'hidden' : ''}`}>
        <div className="image-overlay--left__buttons">
          <button
            type="button"
            className={`image-overlay--left__button ${showInterface ? ACTIVE_CLASS : ''}`}
            onClick={() => toggleInterface()}
          >
            <CategoryIconSvg />
            {Translation.interface}
          </button>
          <button
            type="button"
            className={`image-overlay--left__button ${layoutState.isShortcutsOpened ? ACTIVE_CLASS : ''}`}
            onClick={() => toggleShortcuts()}
          >
            <RocketIconSvg />
            {Translation.shortcuts}
          </button>
          <button
            type="button"
            className="image-overlay--left__button disabled"
            onClick={() => sendInRedo()}
          >
            <RedoIconSvg />
            {Translation.redo}
          </button>
        </div>
      </div>

      {!showInterface && (
        <>
          <CollapsibleCommentOverlay
            image={orderState.images[orderState.overlayImgIndex]}
            refreshGallery={refreshGallery}
          />
          <CollapsibleImageInfo
            image={orderState.images[orderState.overlayImgIndex]}
            refreshGallery={refreshGallery}
            resetInterval={resetInterval}
            isOriginalImage={isOriginalImage}
            originalImageDetails={originalImageDetails}
          />
        </>
      )}
    </div>
  )
}

Overlay.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Overlay.defaultProps = {
  refreshGallery: () => { },
  resetInterval: () => { },
}

export default Overlay
