import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'
import {
  getPathColor,
  hasClass,
} from '../../../helpers/Utils'
import {
  HIDE_DROPDOWN_TIMEOUT,
  CONTROLS_DEFAULT_STATE,
  ALL_LAYERS_KEY,
  PATH_WIDTH_MIN,
  PATH_WIDTH_MAX,
  SHOW_PATH_PREVIEW_CLASS,
  PREVIEW_GRID_CLASS,
  ACTIVE_CLASS,
  PATH_DISPLAY_RATIO,
  GALLERY_BG_COLOR,
  PREVIEW_BG_COLOR,
} from '../../../helpers/Constants'

import Range from '../../../components/Range'
import Checkbox from '../../../components/Checkbox'
import Toggle from '../../../components/Toggle'
import ImageSizeRange from '../ImageSizeRange'

import { ReactComponent as MenuImageSizeSvg } from '../../../svg/menu_image_size.svg'
import { ReactComponent as MenuChangeBackgroundSvg } from '../../../svg/menu_change_background.svg'
import { ReactComponent as MenuDisplaySettingsSvg } from '../../../svg/menu_display_settings.svg'
import { ReactComponent as MenuShowGridsSvg } from '../../../svg/menu_show_grids.svg'
import { ReactComponent as MenuShowClippingPathsSvg } from '../../../svg/menu_show_clipping_paths.svg'
import { ReactComponent as MenuShowLayersSvg } from '../../../svg/menu_show_layers.svg'
import { ReactComponent as PreviewTypeGallerySvg } from '../../../svg/preview_type_gallery.svg'
import { ReactComponent as LayerRadioButtonIconUnselectedSvg } from '../../../svg/layer_radio_button_unselected.svg'
import { ReactComponent as LayerRadioButtonIconSelectedSvg } from '../../../svg/layer_radio_button_selected.svg'
import { ReactComponent as BackgroundDropdown1Svg } from '../../../svg/background_dropdown_1.svg'
import { ReactComponent as BackgroundDropdown2Svg } from '../../../svg/background_dropdown_2.svg'

import './index.scss'

const Controls = () => {
  const layoutState = useStoreState((state) => ({
    controlsState: state.layout.controlsState,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateControlsState: actions.layout.updateControlsState,
  }))

  const orderState = useStoreState((state) => ({
    gallery: state.order.gallery,
    orderId: state.order.orderId,
    imageType: state.order.imageType,
    imageSize: state.order.imageSize,
    imageSort: state.order.imageSort,
    images: state.order.images,
    paths: state.order.paths,
    pathRange: state.order.pathRange,
    selectedImagePaths: state.order.selectedImagePaths,
    layerUrls: state.order.layerUrls,
    selectedLayer: state.order.selectedLayer,
    selectedLayers: state.order.selectedLayers,
    selectedGrids: state.order.selectedGrids,
  }))

  const orderActions = useStoreActions((actions) => ({
    setImageSort: actions.order.setImageSort,
    setImageSize: actions.order.setImageSize,
    getPath: actions.order.getPath,
    setPathRange: actions.order.setPathRange,
    setSelectedImagePaths: actions.order.setSelectedImagePaths,
    getLayer: actions.order.getLayer,
    setSelectedLayer: actions.order.setSelectedLayer,
    setAllSelectedLayers: actions.order.setAllSelectedLayers,
    setSelectedGrids: actions.order.setSelectedGrids,
  }))

  const properties = [
    { name: 'filename', label: Translation.filename },
    { name: 'folder', label: Translation.folder },
    { name: 'resolution', label: Translation.resolution },
    { name: 'dpi', label: Translation.dpi.toUpperCase() },
    { name: 'data_sizes', label: Translation.data_sizes },
    { name: 'colour_space', label: Translation.colour_space },
    { name: 'background_colour', label: Translation.background_colour },
    { name: 'colour_depth', label: Translation.colour_depth },
    { name: 'exif_data', label: Translation.exif_data },
    { name: 'meta_data', label: Translation.meta_data },
    { name: 'paths', label: Translation.paths },
    { name: 'layers', label: Translation.layers },
  ]

  const isMouseOverRef = useRef(false)
  const isOpenedRef = useRef(false)
  const [selectedProperties, setSelectedProperties] = useState(properties)
  const [shortenNames, setShortenNames] = useState(true)
  const [previewSize, setPreviewSize] = useState('gallery') // gallery or list
  const [gallerySize, setGallerySize] = useState('imageSize') // imageSize or grid
  const [gridSize, setGridSize] = useState(1)
  const [selectedImagePaths, setSelectedImagePaths] = useState([])

  useEffect(() => {
    isOpenedRef.current = Object.values(layoutState.controlsState).some((value) => value)
  }, [layoutState.controlsState])

  useEffect(() => {
    document.querySelectorAll(`.${SHOW_PATH_PREVIEW_CLASS}`).forEach((path) => {
      path?.querySelector('path').setAttribute('stroke-width', orderState.pathRange / PATH_DISPLAY_RATIO)
    })
  }, [orderState.pathRange])

  useEffect(() => {
    const defaultSelectedGrids = orderState.gallery?.grids?.filter((grid) => grid?.is_show)
    const newSelectedGrids = [...orderState.selectedGrids, ...(defaultSelectedGrids || [])]
    orderActions.setSelectedGrids(newSelectedGrids)
  }, [orderState.gallery?.grids?.length])

  const onHandleMenuToggle = (e) => {
    e.stopPropagation()

    if (!hasClass(e.currentTarget, 'header--controls__icon')) return

    layoutActions.updateControlsState({
      ...CONTROLS_DEFAULT_STATE,
      [e.currentTarget.id]: !layoutState.controlsState[e.currentTarget.id],
    })
  }

  const onHandleMouseLeave = () => {
    isMouseOverRef.current = false

    setTimeout(() => {
      if (isOpenedRef.current && !isMouseOverRef.current) {
        layoutActions.updateControlsState(CONTROLS_DEFAULT_STATE)
      }
    }, HIDE_DROPDOWN_TIMEOUT)
  }

  const onHandleMouseEnter = () => {
    isMouseOverRef.current = true
  }

  // const onHandleImageSize = (e) => {
  //   e.preventDefault()

  //   const imageSizeUpdate = parseInt(e.currentTarget.dataset.imageSizeUpdate, 10)

  //   orderActions.setImageSize(imageSizeUpdate)

  //   navigate(getGalleryUrl(
  //     userState.user.role_after_login,
  //     orderState.imageType,
  //     orderState.orderId,
  //     imageSizeUpdate,
  //     orderState.imageSort,
  //   ))
  // }

  const onChangePathRange = (e) => {
    const { value } = e.target
    orderActions.setPathRange(parseInt(value, 10))
  }

  const onSelectGrid = (grid) => {
    const newSelectedGrids = [...orderState.selectedGrids]
    const index = newSelectedGrids.findIndex((selectedGrid) => selectedGrid.name === grid.name)
    if (index > -1) {
      newSelectedGrids.splice(index, 1)
      document
        .querySelectorAll(`.${PREVIEW_GRID_CLASS}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`)
        ?.forEach((gridElement) => {
          gridElement?.remove()
        })
    } else {
      newSelectedGrids.push(grid)
    }
    orderActions.setSelectedGrids(newSelectedGrids)
  }

  const onSelectProperty = (property) => {
    setSelectedProperties((prevSelectedProperties) => {
      const newSelectedProperties = [...prevSelectedProperties]
      const index = newSelectedProperties.findIndex((selectedProperty) => selectedProperty.name === property.name)
      if (index > -1) {
        newSelectedProperties.splice(index, 1)
      } else {
        newSelectedProperties.push(property)
      }
      return newSelectedProperties
    })
  }

  const onSelectAllProperties = () => {
    if (properties.length === selectedProperties.length) {
      setSelectedProperties([])
    } else {
      setSelectedProperties(properties)
    }
  }

  const pathCount = (path) => {
    let count = 0
    orderState.images?.forEach((image) => {
      if (image.path?.[path]) {
        count += 1
      }
    })
    return count
  }

  const onSelectPath = async (e, path) => {
    setSelectedImagePaths((prevSelectedImagePaths) => {
      const newSelectedImagePaths = [...prevSelectedImagePaths]
      const index = newSelectedImagePaths.findIndex((selectedPath) => selectedPath === path)
      if (index > -1) {
        newSelectedImagePaths.splice(index, 1)
      } else {
        newSelectedImagePaths.push(path)
      }
      return newSelectedImagePaths
    })

    const imageIds = []
    const s3Paths = []
    orderState.images.forEach((image) => {
      if (image.path?.[path]) {
        imageIds.push(image.id)
        s3Paths.push(image.path[path]?.line?.s3_path)
      }
    })

    const selectedImagePathsCopy = { ...orderState.selectedImagePaths }
    orderState.images.forEach((image) => {
      if (!selectedImagePathsCopy[image.id]) selectedImagePathsCopy[image.id] = {}
      selectedImagePathsCopy[image.id][path] = e.currentTarget.checked
    })
    orderActions.setSelectedImagePaths(selectedImagePathsCopy)

    orderState.images.forEach((image) => {
      if (!selectedImagePathsCopy?.[image.id]?.[path]) {
        document.getElementById(`${image.id}-${path}-preview`)?.remove()
      }
    })

    const hasAllPath = imageIds.every((imageId, index) => orderState.paths?.[imageId]?.[s3Paths[index]])
    if (hasAllPath) return

    if (e.currentTarget.checked) {
      await orderActions.getPath({
        image_ids: imageIds,
        s3_paths: s3Paths,
      })
    }
  }

  const onSelectAllPaths = (e) => {
    if (e.currentTarget.checked) {
      let pathIndex = 0
      for (let i = 0; i < orderState.gallery.path_names.length; i += 1) {
        const name = orderState.gallery.path_names[i]
        if (!selectedImagePaths.includes(name)) {
          // eslint-disable-next-line no-loop-func
          (() => {
            setTimeout(() => {
              onSelectPath({ currentTarget: { checked: true } }, name).then(() => { })
            }, pathIndex * 30)
          })(pathIndex += 1)
        }
      }
    } else {
      for (let i = 0; i < orderState.gallery.path_names.length; i += 1) {
        onSelectPath({ currentTarget: { checked: false } }, orderState.gallery.path_names[i]).then(() => { })
      }
    }
  }

  const onHandleSelectAllLayers = () => {
    const selectedLayers = {}
    orderState.images.forEach((image) => {
      selectedLayers[image.id] = `${ALL_LAYERS_KEY}-${image.id}`
    })
    orderActions.setAllSelectedLayers(selectedLayers)
    orderActions.setSelectedLayer(ALL_LAYERS_KEY)
  }

  const onHandleSelectLayer = (e) => {
    const selectedLayers = { ...orderState.selectedLayers }
    orderState.images.forEach((image) => {
      const layer = image.layer?.find((l) => l.name === e.target.value)
      if (layer) {
        selectedLayers[image.id] = layer.id
      }
    })
    orderActions.setAllSelectedLayers(selectedLayers)
    orderActions.setSelectedLayer(e.target.value)
  }

  return (
    <div className="header--wraps header--controls__wrap" onMouseLeave={onHandleMouseLeave} onMouseEnter={onHandleMouseEnter}>
      <div className="menu--header header--controls__control">
        <div onClick={onHandleMenuToggle} id="imageSize" className="header--controls__icon">
          <MenuImageSizeSvg />
        </div>

        <div
          style={{ display: layoutState.controlsState.imageSize ? '' : 'none' }}
          className="header--controls__container preview-type-container"
        >
          <p className="container-title roobert-medium">{Translation.preview_type}</p>

          <div className="preview-type">
            <div className="left">
              <div className="left-top">
                <input
                  type="radio"
                  className="preview-type-radio"
                  id="previewTypeGallery"
                  name="previewType"
                  value="gallery"
                  checked={previewSize === 'gallery'}
                  onChange={() => {
                    setPreviewSize('gallery')
                  }}
                />
                <label htmlFor="previewTypeGallery" className="radio-icon">
                  {previewSize === 'gallery' ? <LayerRadioButtonIconSelectedSvg /> : <LayerRadioButtonIconUnselectedSvg />}
                </label>
                <label htmlFor="previewTypeGallery" className="preview-type--label">{Translation.gallery}</label>
              </div>
              <div className="left-bottom">
                <PreviewTypeGallerySvg />
              </div>
            </div>
            <div className="right">
              <div className="right-top">
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation()
                    setGallerySize('imageSize')
                  }}
                  className={`btn-gallery-size roobert-medium ${gallerySize === 'imageSize' ? ACTIVE_CLASS : ''}`}
                >
                  {Translation.use_image_size}
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation()
                    setGallerySize('grid')
                  }}
                  className={`btn-gallery-size roobert-medium ${gallerySize === 'grid' ? ACTIVE_CLASS : ''}`}
                >
                  {Translation.use_grid}
                </button>
              </div>
              <div className="right-bottom">
                {gallerySize === 'imageSize' && (
                  <div className="image-size">
                    <ImageSizeRange />
                  </div>
                )}
                {gallerySize === 'grid' && (
                  <div className="grid-size">
                    <p className="grid-size-desc">
                      {Translation.grid_size}
                      :&nbsp;
                      <span>{gridSize}</span>
                    </p>
                    <Range
                      value={gridSize}
                      min={1}
                      max={12}
                      showLabels
                      onChange={(e) => {
                        e.preventDefault()
                        setGridSize(parseInt(e.target.value, 10))
                      }}
                    />
                    <p className="notice">{Translation.notice_images_expand}</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="preview-type list">
            <div className="left">
              <div className="left-top">
                <input
                  type="radio"
                  className="preview-type-radio"
                  id="previewTypeList"
                  name="previewType"
                  value="list"
                  checked={previewSize === 'list'}
                  onChange={() => {
                    setPreviewSize('list')
                  }}
                />
                <label htmlFor="previewTypeList" className="radio-icon">
                  {previewSize === 'list' ? <LayerRadioButtonIconSelectedSvg /> : <LayerRadioButtonIconUnselectedSvg />}
                </label>
                <label htmlFor="previewTypeList" className="preview-type--label">{Translation.list}</label>
              </div>
              <div className="left-bottom">
                <PreviewTypeGallerySvg />
              </div>
            </div>
            <div className="right">
              <p className="description">{Translation.in_list_view}</p>
            </div>
          </div>
        </div>

        {/* <ul id="sizes-menu--list" className="menu--list" style={{ display: layoutState.controlsState.imageSize
          ? '' : 'none' }}>
          <li className={`menu__item ${IMAGE_SIZES.small === orderState.imageSize ? 'menu__item--active' : ''}`}>
            <a href="#" onClick={onHandleImageSize} data-image-size-update={IMAGE_SIZES.small}>
              {Translation.gallery_small}
            </a>
          </li>
          <li className={`menu__item ${IMAGE_SIZES.medium === orderState.imageSize ? 'menu__item--active' : ''}`}>
            <a href="#" onClick={onHandleImageSize} data-image-size-update={IMAGE_SIZES.medium}>
              {Translation.gallery_medium}
            </a>
          </li>
          <li className={`menu__item ${IMAGE_SIZES.large === orderState.imageSize ? 'menu__item--active' : ''}`}>
            <a href="#" onClick={onHandleImageSize} data-image-size-update={IMAGE_SIZES.large}>
              {Translation.gallery_grid}
            </a>
          </li>
          <li className="menu__item" style={{ display: 'none' }}>
            <a href="#">
              {Translation.gallery_list}
            </a>
          </li>
        </ul> */}
      </div>

      <div className="menu--header header--controls__control">
        <div onClick={onHandleMenuToggle} id="changeBackground" className="header--controls__icon">
          <MenuChangeBackgroundSvg />
        </div>

        <div
          style={{ display: layoutState.controlsState.changeBackground ? '' : 'none' }}
          className="header--controls__container background-container"
        >
          <div className="left">
            <p className="container-title roobert-medium">{Translation.background}</p>

            <div className="background-type-container">
              <div className="color" style={{ backgroundColor: GALLERY_BG_COLOR }} />
              <span className="gallery-background">
                {Translation.gallery_background}
              </span>
            </div>

            <Toggle
              checked
              onChange={() => { }}
              id="mask-alpha"
              label={Translation.bottom_spacing}
            />

            <div className="separator" />

            <div className="background-type-container">
              <div className="color" style={{ backgroundColor: PREVIEW_BG_COLOR }} />
              <span className="gallery-background">
                {Translation.image_background}
              </span>
            </div>
          </div>
          <div className="right">
            <BackgroundDropdown1Svg />
            <BackgroundDropdown2Svg />
          </div>

        </div>
      </div>

      <div className="menu--header header--controls__control">
        <div onClick={onHandleMenuToggle} id="displaySettings" className="header--controls__icon">
          <MenuDisplaySettingsSvg />
        </div>

        <div
          style={{ display: layoutState.controlsState.displaySettings ? '' : 'none' }}
          className="header--controls__container properties-container"
        >
          <p className="container-title">{Translation.properties}</p>

          <div className="shorten">
            <Toggle
              checked={shortenNames}
              onChange={() => setShortenNames((prevShortenNames) => !prevShortenNames)}
              id="shorten_names"
              label={Translation.shorten_names}
            />
          </div>

          <div className="separator" />

          <div className="select-all">
            <Checkbox
              id="select-all-properties"
              label={Translation.select_all}
              checked={properties.length === selectedProperties.length}
              onChange={onSelectAllProperties}
              halfChecked={selectedProperties.length > 0 && selectedProperties.length < properties.length}
            />
          </div>

          {properties.map((property) => (
            <div className="property" key={property.name}>
              <Checkbox
                label={property.label}
                id={`property-${property.name}`}
                checked={selectedProperties.some((selectedProperty) => selectedProperty.name === property.name)}
                onChange={() => onSelectProperty(property)}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="menu--header header--controls__control">
        <div onClick={onHandleMenuToggle} id="showGrids" className="header--controls__icon">
          <MenuShowGridsSvg />
        </div>

        <div
          style={{ display: layoutState.controlsState.showGrids ? '' : 'none' }}
          className="header--controls__container grids-container"
        >
          <p className="container-title roobert-medium">{Translation.grids}</p>
          <div className="separator" />
          {orderState.gallery?.grids?.length > 0 && (
            orderState.gallery?.grids.map((grid, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                <div className="grid-list-item">
                  <div
                    className="grid-item-color"
                    style={{
                      backgroundColor:
                        orderState.selectedGrids.some((selectedGrid) => selectedGrid.name === grid.name)
                          ? getPathColor(index)
                          : null,
                    }}
                  />
                  <Checkbox
                    label={
                      (
                        <span className="overflow-ellipsis">
                          {grid.name || grid.name_short}
                          {' '}
                          <span className="grid-item-template-name">{grid.template_name}</span>
                        </span>
                      )
                    }
                    id={`grid-control-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`}
                    checked={orderState.selectedGrids.some((selectedGrid) => selectedGrid.name === grid.name)}
                    onChange={() => onSelectGrid(grid)}
                  />
                </div>
                {/* TODO next line is for only design */}
                {index % 10 === 0 ? <div className="separator" /> : null}
              </div>
            ))
          )}
          <p className="show-more">{Translation.show_more}</p>
        </div>

      </div>

      {orderState.gallery?.path_names?.length > 0 && (
        <div className="menu--header header--controls__control">
          <div onClick={onHandleMenuToggle} id="showClippingPaths" className="header--controls__icon">
            <MenuShowClippingPathsSvg />
          </div>

          <div
            style={{ display: layoutState.controlsState.showClippingPaths ? '' : 'none' }}
            className="header--controls__container paths-container"
          >
            <p className="container-title">{Translation.paths}</p>
            <span className="line-width-label">{Translation.path_width}</span>
            <Range
              value={orderState.pathRange}
              min={PATH_WIDTH_MIN}
              max={PATH_WIDTH_MAX}
              showLabels
              onChange={onChangePathRange}
            />
            <div className="separator" />

            <div className="paths-check-list">
              {orderState.gallery?.path_names?.length > 1 && (
              <div className="select-all">
                <Checkbox
                  id="path-control-all"
                  label={Translation.select_all}
                  checked={selectedImagePaths.length === orderState.gallery?.path_names?.length}
                  onChange={onSelectAllPaths}
                  halfChecked={
                    selectedImagePaths.length > 0 && selectedImagePaths.length < orderState.gallery?.path_names?.length
                  }
                />
              </div>
              )}

              {orderState.gallery?.path_names.map((name, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="path-item">
                  <div className="path-item-left">
                    <div
                      className="path-color"
                      style={{
                        backgroundColor: getPathColor(index),
                      }}
                    />
                    <Checkbox
                      id={`path-control-${index}`}
                      label={name}
                      checked={selectedImagePaths.includes(name)}
                      onChange={(e) => onSelectPath(e, name)}
                    />
                  </div>
                  <span className="path-item-right">
                    {pathCount(name)}
                    {' '}
                    images
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="menu--header header--controls__control">
        <div onClick={onHandleMenuToggle} id="showLayers" className="header--controls__icon">
          <MenuShowLayersSvg />
        </div>

        <div
          style={{ display: layoutState.controlsState.showLayers ? '' : 'none' }}
          className="header--controls__container layers-container"
        >
          <p className="container-title">{Translation.layers_channels}</p>

          {orderState?.gallery && Object.keys(orderState?.gallery?.layer_names)?.length > 0 && (
            <>
              <p className="container-title">{Translation.layers}</p>
              <div className="layer-item">
                <div className="layer-item-left">
                  <input
                    type="radio"
                    className="layer-radio"
                    id="all_layers"
                    name="layerRadio"
                    value="all_layers"
                    checked={orderState.selectedLayer === ALL_LAYERS_KEY}
                    onChange={onHandleSelectAllLayers}
                  />
                  <label htmlFor="all_layers" className="radio-icon">
                    {orderState.selectedLayer === ALL_LAYERS_KEY
                      ? <LayerRadioButtonIconSelectedSvg /> : <LayerRadioButtonIconUnselectedSvg />}
                  </label>
                  <label htmlFor="all_layers" className="layer--label">{Translation.all_layers_visible}</label>
                </div>
                <span className="layer-count">{Translation.images}</span>
              </div>

              {Object.keys(orderState?.gallery?.layer_names).map((key, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="layer-item">
                  <div className="layer-item-left">
                    <input
                      type="radio"
                      className="layer-radio"
                      id={`layer-${key}`}
                      name="layerRadio"
                      value={key}
                      checked={orderState.selectedLayer === key}
                      onChange={onHandleSelectLayer}
                    />
                    <label htmlFor={`layer-${key}`} className="radio-icon">
                      {orderState.selectedLayer === key
                        ? <LayerRadioButtonIconSelectedSvg /> : <LayerRadioButtonIconUnselectedSvg />}
                    </label>
                    <label htmlFor={`layer-${key}`} className="layer--label">{key.replace(/&nbsp;/g, ' ')}</label>
                  </div>
                  <span className="layer-count">{orderState.gallery?.layer_names[key]}</span>
                </div>
              ))}

              <div className="separator" />
            </>
          )}

          <p className="container-title">{Translation.masks}</p>
          <div className="mask-item">
            <Toggle
              checked
              onChange={() => { }}
              id="mask-model"
              label={
                (
                  <>
                    {Translation.model}
                    &nbsp;
                    <span className="mask-desc">{`(${Translation.layer_mask})`}</span>
                  </>
                )
              }
            />
            <span className="mask-count">6</span>
          </div>
          <div className="mask-item">
            <Toggle
              checked
              onChange={() => { }}
              id="mask-alpha"
              label={
                (
                  <>
                    {Translation.alpha_1}
                    &nbsp;
                    <span className="mask-desc">{`(${Translation.alpha_channel})`}</span>
                  </>
                )
              }
            />
            <span className="mask-count">8</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Controls
