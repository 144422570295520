import React from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { COMMENT_STATUSES, COMMENT_TYPES } from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'
import { getEndpointUrl, prepareComment } from '../../../helpers/Utils'
import { API_ENDPOINTS_GALLERY } from '../../../helpers/Urls'

import { ReactComponent as CrossSvg } from '../../../svg/action_cross.svg'

import './index.scss'

const Comment = ({
  comment, isLast, refreshGallery, resetInterval,
}) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderActions = useStoreActions((actions) => ({
    imageAction: actions.order.imageAction,
  }))

  const handleCommentDelete = async (e) => {
    e.stopPropagation()

    const res = await orderActions.imageAction({
      url: getEndpointUrl(userState.user.role_after_login, API_ENDPOINTS_GALLERY.commentDelete),
      body: {
        comment_ids: [parseInt(comment.id.toString(), 10)],
      },
    })

    if (res.success) {
      refreshGallery()
      resetInterval()
    }
  }

  return (
    <>
      <div className="image-preview--comment__wrap">
        {(comment.type === COMMENT_TYPES.qaClient) && (
          <div className={`image-preview--comment__icon image-preview--comment__icon--client 
            ${parseInt(comment.status.toString(), 10) === COMMENT_STATUSES.active
            ? 'image-preview__icon--client__approved' : ''}`}
          >
            {Translation.client_short}
          </div>
        )}

        <div className="image-preview--comment">
          {prepareComment(comment.comment)}
        </div>

        <div className="image-preview--comment__delete" onClick={handleCommentDelete}>
          <CrossSvg />
        </div>
      </div>

      {!isLast && <div className="image-preview--comments__separator" />}
    </>
  )
}

Comment.propTypes = {
  comment: PropTypes.instanceOf(Object),
  isLast: PropTypes.bool,
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Comment.defaultProps = {
  comment: {},
  isLast: false,
  refreshGallery: () => { },
  resetInterval: () => { },
}

export default Comment
