import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'

import './index.scss'

const Loader = ({ isLoading }) => (
  <div className="dialog--update" style={isLoading ? {} : { display: 'none' }}>
    <div className="dialog-loader--wrap">
      {Translation.updating}
      ...
    </div>
  </div>
)

Loader.propTypes = {
  isLoading: PropTypes.bool,
}

Loader.defaultProps = {
  isLoading: false,
}

export default Loader
